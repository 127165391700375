import { OccupationCategory } from '@core/enum/occupation-category';
import { DateType } from '@core/model';

export class EmploymentData {
    public occupationCategory: OccupationCategory;
    // fixme CVA
    public employmentDate: DateType;

    public externalId: string;
}
