import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Package } from '@core/model';
import { isEmpty, flatten, uniq } from 'lodash';

@Directive({
    selector: '[edValidatePackages]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PackagesValidatorDirective),
            multi: true
        }
    ]
})
export class PackagesValidatorDirective implements Validator {

    public static checkValidity(packages: Package[]): ValidationErrors | null {
        if (isEmpty(packages)) {
            return null;
        }

        const isConsentValid = packages.every(p => !p?.packageConsent || p?.packageConsent?.value === true);

        if (!isConsentValid) {
            return {packageConsent: true};
        }

        const notAllowedPolicyPackageCodes: string[] = flatten<string>(packages?.map((p: Package) => p.notAllowedPolicyPackageCode))
        const disabledPackageCodes: string[] = uniq(notAllowedPolicyPackageCodes);
        const isAllowedPackagesNotValid = packages.some(p => disabledPackageCodes.includes(p.code));

        if (isAllowedPackagesNotValid) {
            return {notAllowedPackage: true};
        }

        return null;
    }

    public validate(field: FormControl): ValidationErrors | null {
        const value: Package[] = field.value;
        return PackagesValidatorDirective.checkValidity(value);
    }
}
