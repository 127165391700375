import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { gt, isNil, lt } from 'lodash';

@Directive({
    selector: '[edValidateNumberRange]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: InputNumberRangeValidateDirective,
            multi: true
        }
    ]
})
export class InputNumberRangeValidateDirective implements Validator {
    @Input()
    public min: number;

    @Input()
    public max: number;

    // tslint:disable-next-line:cyclomatic-complexity
    public static checkValidity(value: number,
                                min: number,
                                max: number): ValidationErrors | null {
        let hasError = null;
        if (!isNil(min) && !hasError) {
            hasError = InputNumberRangeValidateDirective.validateMin(value, min);
        }

        if (!isNil(max) && !hasError) {
            hasError = InputNumberRangeValidateDirective.validateMax(value, max);
        }

        return hasError;
    }

    private static validateMin(value: number, min: number): ValidationErrors | null {
        const invalidValue = lt(value, min);
        if (invalidValue) {
            return {min: {minValue: min}};
        } else {
            return null;
        }
    }

    private static validateMax(value: number, max: number): ValidationErrors | null {
        const invalidValue = gt(value, max);
        if (invalidValue) {
            return {max: {maxValue: max}};
        } else {
            return null;
        }
    }

    public validate(field: FormControl): ValidationErrors | null {
        const hasNoValue = isNil(field.value) || (isNil(this.min) && isNil(this.max));
        if (hasNoValue) {
            return null;
        } else {
            return InputNumberRangeValidateDirective.checkValidity(field.value, this.min, this.max);
        }
    }


}
