import { Injectable } from '@angular/core';
import { Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';

import { RouteUrl } from '@core/enum';
import { ContextUrlService } from '@core/services';

@Injectable({providedIn: 'root'})
export class ApplicationEntryManagerService {

    private readonly applicationEntryRoute = RouteUrl.START;
    private readonly applicationSecureEntryRoute = RouteUrl.SECURE;
    private readonly entryRoutes = [this.applicationEntryRoute.toString(), this.applicationSecureEntryRoute.toString()];

    constructor(private router: Router,
                private contextUrlService: ContextUrlService) {
    }

    public currentRouteSameAsEntryRoute(): boolean {
        const urlSegment: UrlSegment = this.getPrimaryUrlSegment(this.getUrlTree());
        return urlSegment && (this.entryRoutes.includes(urlSegment.path));
    }

    public checkContextAndRedirectToEntryRoute(): void {
        const contextHash = this.contextUrlService.getContextHashFromUrl();
        if (contextHash) {
            this.goToEntryRouteWithContext(contextHash);
        } else {
            this.goToEntryRouteWithoutContext();
        }
    }

    public redirectToSuccessPage(): void {
        this.router.navigate([RouteUrl.SUCCESS]);
    }

    private goToEntryRouteWithoutContext(): void {
        this.router.navigate([this.applicationEntryRoute]);
    }

    private goToEntryRouteWithContext(contextHash: string): void {
        this.router.navigate([this.applicationEntryRoute, contextHash]);
    }

    private getPrimaryUrlSegment(urlTree: UrlTree): UrlSegment | null {
        const rootchildren = urlTree.root.children;
        if (rootchildren && rootchildren.primary) {
            const primaryUrlSegmentGroup: UrlSegmentGroup = urlTree.root.children.primary;
            return primaryUrlSegmentGroup.segments[0] ? primaryUrlSegmentGroup.segments[0] : null;
        }
        return null;
    }

    private getUrlTree(): UrlTree {
        const url = this.router.url;
        return this.router.parseUrl(url);
    }
}
