import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { StorageKey } from '../enum';
import { State } from '../model';

@Injectable({providedIn: 'root'})
export class StateService {

    private store: BehaviorSubject<State>;

    constructor() {
        this.store = new BehaviorSubject<State>(this.getInitialState());
    }

    public get<T>(storeKey: StorageKey): T {
        return cloneDeep(this.store.getValue()[storeKey]) as T;
    }

    public observe<T>(storeKey: StorageKey): Observable<T> {
        return this.store.asObservable()
            .pipe(
                map((state: State) => state[storeKey]),
                distinctUntilChanged((prevValue: T, currValue: T) => isEqual(prevValue, currValue)),
                map((value: T): T => cloneDeep(value)));
    }

    public observeState(): Observable<State> {
        return this.store.asObservable()
            .pipe(
                distinctUntilChanged((prevValue: State, currValue: State) => isEqual(prevValue, currValue)),
                map((value: State): State => cloneDeep(value)));
    }

    public set(storeKey: StorageKey, payload: any): void {
        this.setLocalStorage(storeKey, payload);
        const oldState = this.store.getValue();
        const newState = {...oldState, [storeKey]: payload};
        this.store.next(cloneDeep(newState));
    }

    public remove(storeKey: StorageKey): void {
        window.localStorage.removeItem(storeKey);
        const oldState = this.store.getValue();
        delete oldState[storeKey];
        this.store.next(cloneDeep(oldState));
    }

    public clear(): void {
        const casTicket = this.get(StorageKey.CAS_TICKET);
        const customerData = this.get(StorageKey.CUSTOMER_DATA);

        window.localStorage.clear();
        if (casTicket) {
            this.set(StorageKey.CAS_TICKET, casTicket);
        }
        if (customerData) {
            this.set(StorageKey.CUSTOMER_DATA, customerData);
        }

        this.store.next(this.getInitialState());
    }

    private setLocalStorage(storeKey: StorageKey, payload: any): void {
        window.localStorage.setItem(storeKey, JSON.stringify(payload));
    }

    private getLocalStorage<T>(storeKey: StorageKey): T {
        return JSON.parse(window.localStorage.getItem(storeKey));
    }

    private getInitialState(): State {
        const store: State = {};
        Object.values(StorageKey).forEach((storeKey: string) => {
            const localStorageValue = window.localStorage.getItem(storeKey);
            if (localStorageValue !== null) {
                store[storeKey] = JSON.parse(localStorageValue);
            }

        });

        return store;
    }
}
