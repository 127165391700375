import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CustomerDataService } from '@core/services';

@Component({
  selector: 'ed-app-secure-initialization',
  template: ''
})
export class SecureInitializationComponent implements OnInit {

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly customerDataService: CustomerDataService) {
  }

  public ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(queryParams => {
      const ticket = queryParams.token;
      const dest = queryParams.dest;

      if (ticket) {
        const destUrl = new URL(dest, location.toString());

        /*
        I used window.location.href for redirect because using router not working properly during redirection.
        Router doesn't invoke fetching policy data
        */
        this.customerDataService.validateLoggedUser(ticket)
          .then(() => window.location.href = `${destUrl.pathname}?${destUrl.searchParams.toString()}`);

      } else {
        this.router.navigate(['/', { queryParams }]);
      }

    });

  }

}
