import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import moment from 'moment';

import { PeselUtils } from '@shared/pesel-util';
import { DateType } from '@core/model';

@Directive({
    selector: '[edValidateDateCompatibleWithPesel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateCompatibleWithPeselValidatorDirective),
            multi: true
        }
    ]
})
export class DateCompatibleWithPeselValidatorDirective implements Validator {

    @Input()
    private edValidateDateCompatibleWithPesel: string;

    private static compare(date: DateType, pesel: string): ValidationErrors | null {
        if (!PeselUtils.canComparePeselAndBirthDate(pesel, date)) {
            return null;
        }

        if (PeselUtils.comparePeselAndBirthDate(pesel, moment(date))) {
            return null;
        }

        return {dateNotCompatibleWithPesel: true};
    }

    public validate(field: FormControl): ValidationErrors | null {
        return DateCompatibleWithPeselValidatorDirective.compare(
            field.value,
            this.edValidateDateCompatibleWithPesel
        );
    }

}
