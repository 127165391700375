import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNil } from 'lodash';
import moment from 'moment';

import { PbkmVariant, ProductCode, StorageKey } from '@core/enum';
import { Context, DateType, Employee, Offer, Package, Variant } from '@core/model';
import { StateService } from '@core/services/state.service';
import { LANG_STORAGE_NAME } from '@shared/lang-storage-name.const';
import { LANGUAGE } from '@shared/language.const';

import { InsuredPage } from '../../conditions-change/insured/insured-page';
import { InsuredOfferPage } from '../../conditions-change/insured-offer/insured-offer-page';
import { Insured } from '../../conditions-change/insured/insured';
import { OfferPage } from '../../conditions-change/offer/offer-page';
import { InsurerPage } from '../../conditions-change/insurer/insurer-page';
import { SessionStorageService } from './session-storage.service';

const DEFAULT_LANGUAGE = LANGUAGE.PL;

@Injectable({providedIn: 'root'})
export class ContextService {

    constructor(private stateService: StateService,
                private storageService: SessionStorageService) {
    }

    public isPbkmOffer(): boolean {
        return !isNil(this.getPbkmVariant());
    }

    public isConditionsChangeOffer(): boolean {
        const context = this.getContext();
        return context && context.isConditionsChange;
    }

    public getPbkmVariant(): PbkmVariant {
        const proposal = this.getContext();
        return proposal ? proposal.pbkmVariant : null;
    }

    public getProductCode(): ProductCode {
        const proposal = this.getContext();
        return proposal.productCode;
    }

    public getContextEmployee(): Employee {
        const ctx = this.getContext();
        return ctx.employee;
    }

    public getContext(): Context {
        return this.stateService.get(StorageKey.CONTEXT);
    }

    public setShowHealthStatement(showHealthStatement: boolean): void {
        const ctx = this.getContext();
        ctx.showHealthStatement = showHealthStatement;
        return this.stateService.set(StorageKey.CONTEXT, ctx);
    }

    public setPbkmVariant(pbkmVariant: PbkmVariant): void {
        const ctx = this.getContext();
        ctx.pbkmVariant = pbkmVariant;
        return this.stateService.set(StorageKey.CONTEXT, ctx);
    }

    public context$(): Observable<Context> {
        return this.stateService.observe(StorageKey.CONTEXT);
    }

    public getCertificateRef(): string {
        return this.getContext().certificateRef;
    }

    public getLanguageParam(): string {
        const selectedLanguage = this.getSelectedLanguage();
        const language = [DEFAULT_LANGUAGE.toUpperCase()];

        if (selectedLanguage !== DEFAULT_LANGUAGE) {
            language.push(selectedLanguage.toUpperCase());
        }

        return language.join(',');
    }

    public getSelectedLanguage(): string {
        return this.storageService.get(LANG_STORAGE_NAME);
    }

    public getInsuredBirthDate(): DateType {
        return this.getInsured().birthDate;
    }

    public getRelationshipToEmployee(): string {
        return this.getInsured().relationshipToEmployee;
    }

    public isMainInsured(): boolean {
        return this.getInsured().isMain;
    }

    public getInsured(): Insured {
        const page: InsuredPage = this.stateService.get(StorageKey.CONDITIONS_CHANGE_INSURED);
        return page.insured;
    }

    public getStartDate(): DateType {
        const page: InsurerPage = this.stateService.get(StorageKey.CONDITIONS_CHANGE_INSURER);
        return page.startDate;
    }

    public getConditionsChageOffer(): Offer {
        const page: OfferPage = this.stateService.get(StorageKey.CONDITIONS_CHANGE_OFFER);
        return page.offer;
    }

    public getCurrentVariant(): Variant {
        const page: InsuredOfferPage = this.stateService.get(StorageKey.CONDITIONS_CHANGE_INSURED_OFFER);
        return page.insuredOffer.variant;
    }

    public getCurrentPackages(): Package[] {
        const page: InsuredOfferPage = this.stateService.get(StorageKey.CONDITIONS_CHANGE_INSURED_OFFER);
        return page.insuredOffer.packages;
    }

    public getPolicyAnniversary(): DateType {
        const proposal = this.getContext();
        const now = moment(new Date()).startOf('day');
        const currentYear = now.get('year');
        const policyAnniversaryYear = moment(proposal.policyStartDate).set('years', currentYear).startOf('month');
        const hasPassed = now.isAfter(policyAnniversaryYear);
        return policyAnniversaryYear.year(hasPassed ? currentYear + 1 : currentYear);
    }
}
