import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep, flatten, uniq, includes, intersection } from 'lodash';
import { NxCheckboxChangeEvent } from '@aposin/ng-aquila/checkbox';

import { Package } from '@core/model';

@Component({
    selector: 'ed-packages',
    templateUrl: './packages.component.html',
    styleUrls: ['./packages.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PackagesComponent),
        multi: true
    }]
})
export class PackagesComponent implements ControlValueAccessor {

    @Input()
    public packages: Package[];
    @Input()
    public disabled: boolean;

    public selectedCheckboxCodes: string[];
    public disabledPackageCodes: {[packageCode: string]: string[]} = {};

    private mutateNgModel: Package[];
    private propagateToModelChangeEvent: (value: Package[]) => void;
    private propagateToModelTouchEvent: () => void;

    public onBlurHandler(_: Event): void {
        this.propagateToModelTouchEvent();
    }

    public onPackageCheckboxClick(packageCode: string, isExpanded: boolean, $nx: PointerEvent) {
        const index = this.getSelectedPackageIndex(packageCode);
        const selectedPackage = this.mutateNgModel[index];
        const modelPackage = this.getPackageWith(packageCode);

        // if checkbox true, then open expansion panel, otherwise dont move
        if (selectedPackage || !modelPackage.packageConsent || isExpanded) {
            $nx.stopPropagation();
        }
    }

    public changeSelectedPackagesHandler(nx: NxCheckboxChangeEvent): void {
        if (nx.checked) {
            this.selectPackage(nx.value);
        } else {
            this.unselectPackage(nx.value);
        }
        this.propagateToModelChangeEvent(this.mutateNgModel);
    }

    public changeSelectedPackageConsentHandler(packageCode: string, nx: NxCheckboxChangeEvent): void {
        const index = this.getSelectedPackageIndex(packageCode);
        const selectedPackage = this.mutateNgModel[index];
        if (selectedPackage) {
            selectedPackage.packageConsent.value = nx.checked;
            this.propagateToModelChangeEvent(this.mutateNgModel);
        }
    }

    public writeValue(selectedPackages: Package[]): void {
        this.mutateNgModel = !!selectedPackages && Array.isArray(selectedPackages) ? selectedPackages : [];
        const selectedPackageCodes = this.mutateNgModel.map(selectedPackage => selectedPackage.code);
        const selectedConsentCodes = this.mutateNgModel.filter(selectedPackage => !!selectedPackage.packageConsent?.value).map(selectedPackage => this.formatConsentCode(selectedPackage));
        this.selectedCheckboxCodes = [...selectedPackageCodes, ...selectedConsentCodes];
    }

    public registerOnChange(fn: (value: Package[]) => void): void {
        this.propagateToModelChangeEvent = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateToModelTouchEvent = fn;
    }

    public formatConsentCode(value: Package) {
        return value.code + '.' + value.packageConsent?.code;
    }

    private selectPackage(packageCode: string): void {
        const packagetoAdd = this.getPackageWith(packageCode);
        const clone = cloneDeep(packagetoAdd);
        this.mutateNgModel.push(clone);
    }

    private unselectPackage(packageCode: string): void {
        const index = this.getSelectedPackageIndex(packageCode);
        this.mutateNgModel.splice(index, 1);
    }

    private getSelectedPackageIndex(packageCode: string): number {
        return this.mutateNgModel.findIndex(p => p.code === packageCode);
    }

    private getPackageWith(packageCode: string): Package {
        return this.packages.find(p => p.code === packageCode);
    }

    public isPackageNotAllowed(candidate: Package): boolean {
        const notAllowedCodes = uniq(flatten(this.mutateNgModel?.map(p => p.notAllowedPolicyPackageCode)));
        const selectedExcludeCandidate = includes(notAllowedCodes, candidate.code);

        const selectedCodes = this.mutateNgModel?.map(p => p.code);
        const candidateExcludesSelected =  intersection(candidate.notAllowedPolicyPackageCode, selectedCodes).length > 0;

        return selectedExcludeCandidate || candidateExcludesSelected;
    }
}
