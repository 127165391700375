import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isEmpty } from 'lodash';

@Directive({
    selector: '[edValidateRegon]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RegonValidatorDirective),
            multi: true
        }
    ]
})
export class RegonValidatorDirective implements Validator {

    public static checkRegonValidity(regon: string): boolean {
        const weights: number[] = [8, 9, 2, 3, 4, 5, 6, 7];

        if (regon.length !== 9) {
            return false;
        }

        let checksum = 0;
        for (let i = 0; i < regon.length - 1; i++) {
            checksum += weights[i] * parseInt(regon[i], 10);
        }

        let modulo = checksum % 11;

        if (modulo === 10) {
            modulo = 0;
        }

        return modulo === parseInt(regon[8], 10);
    }

    public validate(field: FormControl): ValidationErrors | null {
        let isValid = false;

        if (isEmpty(field.value)) {
            isValid = true;
        } else {
            isValid = RegonValidatorDirective.checkRegonValidity(field.value);
        }

        if (!isValid) {
            return {regon: true};
        }

        return null;
    }
}
