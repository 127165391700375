import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LANGUAGE } from '@shared/language.const';
import { LANG_STORAGE_NAME } from '@shared/lang-storage-name.const';
import { SessionStorageService } from '@core/services';

const DEFAULT_LANGUAGE = LANGUAGE.PL;

@Component({
    selector: 'ed-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
    public LANGUAGE = LANGUAGE;
    public selectedLanguage: string;

    constructor(private translate: TranslateService, private storageService: SessionStorageService) {
    }

    public ngOnInit(): void {
        this.setLanguage();
    }

    public changeLanguage(selectedLang: string): void {
        this.storageService.set(LANG_STORAGE_NAME, selectedLang);
        this.useLanguage(selectedLang);
    }

    private setLanguage(): void {
        const language = this.storageService.get(LANG_STORAGE_NAME);
        this.useLanguage(language || DEFAULT_LANGUAGE);

        if (!language) {
            this.storageService.set(LANG_STORAGE_NAME, DEFAULT_LANGUAGE);
        }
    }

    private useLanguage(language: string): void {
        this.selectedLanguage = language;
        this.translate.setDefaultLang(language);
        this.translate.use(language);
    }
}
