import { BehaviorSubject, Observable } from 'rxjs';

import { LoadingStatus } from '@core/enum/loading-status';

// // fixme przenieść do base/loader
export abstract class LoadingStatusService {
    private statusSubject$ = new BehaviorSubject<LoadingStatus>(LoadingStatus.INITIAL);

    public status$: Observable<LoadingStatus>;

    constructor() {
        this.status$ = this.statusSubject$.asObservable();
    }

    protected changeStatus(status: LoadingStatus): void {
        this.statusSubject$.next(status);
    }

    protected getStatus(): LoadingStatus {
        return this.statusSubject$.getValue();
    }
}
