import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { CoreModule } from '@core/core.module';
import { DateInterceptor } from '@core/http/date-interceptor.service';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { LOADER_CONFIG } from '@shared/translate-http-loader';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SecureInitializationComponent } from './secure-initalization.component';
import { StartModule } from './start/start.module';
import { SuccessPageComponent } from './success-page/success-page.component';

registerLocaleData(localePl);

@NgModule({
    declarations: [
        AppComponent,
        SuccessPageComponent,
        SecureInitializationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NxModalModule.forRoot(),
        TranslateModule.forRoot(LOADER_CONFIG),

        CoreModule,
        StartModule,
        SharedModule,
        AppRoutingModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'pl-PL'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateInterceptor,
            multi: true,
        },
        provideEnvironmentNgxMask()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
