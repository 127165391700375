import { Pipe, PipeTransform } from '@angular/core';

import { DictionaryService } from './dictionary.service';
import { DictionaryEntry } from './dictionary-entry';
import { DictionaryKey } from './dictionary-key';


@Pipe({
    name: 'dictionary'
})
export class DictionaryPipe implements PipeTransform {

    constructor(private dictionaryService: DictionaryService) {
    }

    public transform(dictionaryKey: DictionaryKey, ...args): DictionaryEntry[] {
        return this.dictionaryService.get(dictionaryKey);
    }
}
