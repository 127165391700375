import { Injectable } from '@angular/core';
import { NxDateAdapter } from '@aposin/ng-aquila/datefield';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';

import { LANG_STORAGE_NAME } from '@shared/lang-storage-name.const';
import { LANGUAGE } from '@shared/language.const';
import { SessionStorageService } from './session-storage.service';

const LOCALE_PL = 'pl-PL';
const LOCALE_EN = 'en-US';

@Injectable({providedIn: 'root'})
export class DatepickerLanguageService {

    constructor(private storageService: SessionStorageService,
                private translate: TranslateService) {
    }

    public setLangChange(nxDateAdapter: NxDateAdapter<Moment>): void {
        this.changeLanguage(nxDateAdapter);
        this.translate.onLangChange
            .subscribe(event => this.changeLanguage(nxDateAdapter, event.lang));
    }

    public changeLanguage(adapter: NxDateAdapter<Moment>, language?: string): void {
        if (!language) {
            language = this.storageService.get(LANG_STORAGE_NAME);
        }

        if (language === LANGUAGE.PL) {
            adapter.setLocale(LOCALE_PL);
        } else if (language === LANGUAGE.EN) {
            adapter.setLocale(LOCALE_EN);
        }

    }
}
