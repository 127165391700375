import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Decimal } from 'decimal.js';
import { forIn, isArray, isBoolean, isObject } from 'lodash';
import moment from 'moment';

@Injectable()
export class DateInterceptor implements HttpInterceptor {

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.body) {
            req = req.clone({body: this.prepareBody(req.body)});
        }
        return next.handle(req);
    }

    private prepareBody(body: any): any {
        // tslint:disable-next-line:cyclomatic-complexity
        forIn(body, (value, key) => {
            if (isBoolean(value)) {
                return;
            }

            if (isArray(value)) {
                body[key] = value.map(item => this.prepareBody(item));
            }

            if (isObject(value) && !Decimal.isDecimal(value) && !moment.isMoment(value)) {
                body[key] = this.prepareBody(value);
            }

            if (this.isDateField(key)) {
                body[key] = this.convertDate(value);
            }
        });

        return body;
    }

    private isDateField(key): boolean {
        return key.endsWith('Date') || key === 'date';
    }

    private convertDate(value: any): string {
        if (!value) {
            return value;
        }
        const m = moment(value);
        return m.format('YYYY-MM-DD');
    }
}
