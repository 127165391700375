import { Injectable } from '@angular/core';
import { isObject } from 'lodash';

import { StorageKey } from '@core/enum';
import { Context } from '@core/model';
import { StateService } from '@core/services';

@Injectable({providedIn: 'root'})
export class ContextStorageService {

    constructor(private stateService: StateService) {
    }

    public hasContextFromLocalStorage(): boolean {
        return this.isContext(this.getContext());
    }

    public isContext(context: Context): boolean {
        return isObject(context)
            && !!context.productCode
            && !!context.agentNo
            && !!context.proposalRef;
    }

    public clear(): void {
        this.stateService.clear();
    }

    public save(context: Context): void {
        this.stateService.set(StorageKey.CONTEXT, context);
    }

    private getContext(): Context {
        return this.stateService.get(StorageKey.CONTEXT);
    }
}
