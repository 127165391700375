import { Component, OnInit } from '@angular/core';

import { EntryWithContextService } from './entry-with-context.service';
import { ContextData } from './context-data';

@Component({
    selector: 'ed-entry-with-context',
    templateUrl: './entry-with-context.component.html',
    styleUrls: ['./entry-with-context.component.scss']
})
export class EntryWithContextComponent implements OnInit {
    public contextData: ContextData;

    constructor(private entryWithContextService: EntryWithContextService) {
    }

    public ngOnInit(): void {
        this.entryWithContextService.findContextEntry()
            .subscribe(contextData => this.contextData = contextData);
    }

    public navigate(url: string): void {
        window.location.href = url;
    }
}
