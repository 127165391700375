import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isEmpty } from 'lodash';

@Directive({
    selector: '[edDocumentNo]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DocumentNoValidatorDirective),
            multi: true
        }
    ]
})
export class DocumentNoValidatorDirective implements Validator, OnChanges {

    private static identityCardDocumentType = 'ID';

    @Input('edDocumentNo')
    private edDocumentType: string;

    private field: FormControl;

    private static getLetterValue(letter: string): number {
        const letterValues = [
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
            'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
            'U', 'V', 'W', 'X', 'Y', 'Z'];
        return letterValues.indexOf(letter);
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private static validateField(documentNo: string): ValidationErrors | null {
        if (documentNo.length !== 9) {
            return DocumentNoValidatorDirective.getValidationErrors();
        }

        // tslint:disable-next-line:one-variable-per-declaration
        let sum; let i;

        for (i = 0; i < 3; i++) {
            if (DocumentNoValidatorDirective.getLetterValue(documentNo[i]) < 10) {
                return DocumentNoValidatorDirective.getValidationErrors();
            }
        }

        for (i = 3; i < 9; i++) {
            if (DocumentNoValidatorDirective.getLetterValue(documentNo[i]) < 0
                || DocumentNoValidatorDirective.getLetterValue(documentNo[i]) > 9) {
                return DocumentNoValidatorDirective.getValidationErrors();
            }
        }

        sum = 7 * DocumentNoValidatorDirective.getLetterValue(documentNo[0]) +
            3 * DocumentNoValidatorDirective.getLetterValue(documentNo[1]) +
            1 * DocumentNoValidatorDirective.getLetterValue(documentNo[2]) +
            7 * DocumentNoValidatorDirective.getLetterValue(documentNo[4]) +
            3 * DocumentNoValidatorDirective.getLetterValue(documentNo[5]) +
            1 * DocumentNoValidatorDirective.getLetterValue(documentNo[6]) +
            7 * DocumentNoValidatorDirective.getLetterValue(documentNo[7]) +
            3 * DocumentNoValidatorDirective.getLetterValue(documentNo[8]);
        sum %= 10;

        if (sum !== DocumentNoValidatorDirective.getLetterValue(documentNo[3])) {
            return DocumentNoValidatorDirective.getValidationErrors();
        }
        return null;
    }

    private static getValidationErrors(): ValidationErrors {
        return {documentNumber: true};
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.field) {
            this.field.updateValueAndValidity();
        }
    }

    public validate(field: FormControl): ValidationErrors | null {
        this.field = field;

        if (this.checkIsReadyForValidation(field)) {
            return DocumentNoValidatorDirective.validateField(field.value);
        }
        return null;
    }

    private checkIsReadyForValidation(field: FormControl): boolean {
        return !isEmpty(field)
            && !isEmpty(field.value)
            && this.edDocumentType === DocumentNoValidatorDirective.identityCardDocumentType;
    }
}
