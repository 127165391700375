import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
    selector: '[edValidateNip]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NipValidatorDirective),
            multi: true
        }
    ]
})
export class NipValidatorDirective implements Validator {

    public static checkNipValidity(nip: string): boolean {
        {
            let sum = 0;
            const wages = [6, 5, 7, 2, 3, 4, 5, 6, 7];

            for (let i = 0; i < nip.length - 1; i++) {
                const x = nip.substr(i, 1);
                sum = sum + Number(x) * Number(wages[i]);
            }

            const calculatedChecksum = sum % 11;
            const checksum = Number(nip.substr((nip.length - 1), 1));
            const hasProperFormat = /^(\d{10})$/.test(nip);

            return (checksum === calculatedChecksum && hasProperFormat);
        }
    }

    public validate(field: FormControl): ValidationErrors | null {
        let isValid = false;

        if (_.isEmpty(field.value)) {
            isValid = true;
        } else {
            isValid = NipValidatorDirective.checkNipValidity(field.value);
        }

        if (!isValid) {
            return {nip: true};
        }

        return null;
    }
}
