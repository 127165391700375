import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input } from '@angular/core';
import { cloneDeep } from 'lodash';

import { Variant } from '@core/model';

@Component({
    selector: 'ed-variant',
    templateUrl: './variant.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => VariantComponent),
        multi: true
    }]
})
export class VariantComponent implements ControlValueAccessor {

    @Input()
    public variants: Variant[];
    @Input()
    public disabled: boolean;

    public variantCode: string;

    private mutateNgModel: Variant;
    private propagateToModelChangeEvent: (value: Variant) => void;
    private propagateToModelTouchEvent: () => void;

    public selectVariantHandler(variantCode: string): void {
        const findedVariant: Variant = this.findVariantWith(variantCode);
        const clone = cloneDeep(findedVariant);

        this.setMutateNgModel(clone);
        this.propagateToModelChangeEvent(this.mutateNgModel);
    }

    public onBlurHandler(_: Event): void {
        this.propagateToModelTouchEvent();
    }

    public registerOnChange(fn: (value: Variant) => void): void {
        this.propagateToModelChangeEvent = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateToModelTouchEvent = fn;
    }

    public writeValue(variant: Variant): void {
        this.mutateNgModel = variant;
        this.variantCode = !!this.mutateNgModel && this.mutateNgModel.code ? this.mutateNgModel.code : null;
    }

    private findVariantWith(variantCode: string): Variant {
        return this.variants.find(variant => variant.code === variantCode);
    }

    private setMutateNgModel(variant: Variant): void {
        this.mutateNgModel.cost = variant.cost;
        this.mutateNgModel.description = variant.description;
        this.mutateNgModel.code = variant.code;
        this.mutateNgModel.adultFamilyMemberAvailable = variant.adultFamilyMemberAvailable;
        this.mutateNgModel.childFamilyMemberAvailable = variant.childFamilyMemberAvailable;
        this.mutateNgModel.dynamicSum = variant.dynamicSum;
        this.mutateNgModel.deathBenefitValue = variant.deathBenefitValue;
        this.mutateNgModel.fcl = variant.fcl;
    }
}
