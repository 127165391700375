import { Directive, HostListener, Renderer2, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil } from 'lodash';

@Directive({
    selector: '[edInputCapitalize]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: InputCapitalizeDirective,
        multi: true
    }]
})
export class InputCapitalizeDirective implements ControlValueAccessor {

    private caretPosition: number;
    private previousCaretPosition: number;
    private readonly htmlInputElement: HTMLInputElement;

    // >>>> native html -> model
    private propagateToModelChangeEvent: (value: string) => void;
    private propagateToModelTouchEvent: () => void;

    // <<<<

    public static capitalizeValue(value: string): string {
        return value.charAt(0).toUpperCase() + value.substring(1);
    }

    private static capitalizedSameAsCurrentInputValue(capitalizedInputValue: string, inputValue: string): boolean {
        return capitalizedInputValue === inputValue;
    }

    constructor(viewContainerRef: ViewContainerRef,
                private renderer: Renderer2) {
        this.htmlInputElement = viewContainerRef.element.nativeElement;
    }

    // >>>> model -> native
    public writeValue(value: string | null | number | undefined): void {
        this.setInitialNormalizeValue(value);
    }

    private setInitialNormalizeValue(value: string | number | null | undefined): void {
        if (isNil(value)) {
            value = '';
        }
        this.htmlInputElement.value = value.toString();
    }

    public registerOnChange(fn: (value: string) => void): void {
        this.propagateToModelChangeEvent = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateToModelTouchEvent = fn;
    }

    // <<<<

    // >>>> native html -> model
    public setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.htmlInputElement, 'disabled', isDisabled);
    }

    // <<<<

    @HostListener('blur', ['$event'])
    public onBlurHandler(_: Event): void {
        this.propagateToModelTouchEvent();
    }

    @HostListener('click', ['$event'])
    public onClickHandler(_: Event): void {
        this.updateCaretPosition();
    }

    @HostListener('input', ['$event.target.value'])
    public onInputhandler(value: string): void {
        this.changeInputValue(value);
        // this.propagateToModelTouchEvent();
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private changeInputValue(inputValue: string): void {
        this.updateCaretPosition();

        if (this.previousCaretPosition <= 1 && inputValue.length > 2 && this.caretPosition === inputValue.length) {
            this.setPreviousCaretPosition();
        }

        const capitalizedInputValue = InputCapitalizeDirective.capitalizeValue(inputValue);
        if (!InputCapitalizeDirective.capitalizedSameAsCurrentInputValue(capitalizedInputValue, inputValue)) {
            this.changeCapitalizedValue(capitalizedInputValue);
        }
        this.propagateToModelChangeEvent(this.htmlInputElement.value);
    }

    private updateCaretPosition(): void {
        this.previousCaretPosition = this.caretPosition;
        this.caretPosition = this.htmlInputElement.selectionStart;
    }

    private changeCapitalizedValue(capitalizedValue: string): void {
        this.htmlInputElement.value = capitalizedValue;
        this.htmlInputElement.setSelectionRange(this.caretPosition, this.caretPosition);
    }

    private setPreviousCaretPosition(): void {
        this.htmlInputElement.setSelectionRange(this.previousCaretPosition, this.previousCaretPosition);
    }
}
