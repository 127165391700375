export enum StorageKey {
    CONTEXT = 'context',
    DICTIONARIES = 'dictionaries',
    LOGIN = 'loginPage',
    OFFER = 'offerPage',
    HEALTH_STATEMENT = 'healthStatementPage',
    PERSONAL_DATA = 'personalDataPage',
    ADDRESS = 'addressPage',
    BENEFICIARY = 'beneficiaryPage',
    CONSENTS = 'consentsPage',
    SUBMITTING_STATE = 'submittingState',
    CONDITIONS_CHANGE_INSURER = 'conditionsChangeInsurerPage',
    CONDITIONS_CHANGE_INSURED = 'conditionsChangeInsuredPage',
    CONDITIONS_CHANGE_INSURED_OFFER = 'conditionsChangeInsuredOfferPage',
    CONDITIONS_CHANGE_OFFER = 'conditionsChangeOfferPage',
    CAS_TICKET = 'casTicket',
    CUSTOMER_DATA = 'customerData'
}
