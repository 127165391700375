import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PbkmVariant } from '@core/enum';
import { Variant } from '@core/model';

@Injectable({providedIn: 'root'})
export class PolicyService {

    private readonly url = 'api/policy/';

    constructor(private http: HttpClient) {
    }

    public findPbkmVariant(agentNo: string, groupProposalRef: string, groupPolicyRef: string,
                           productType: string): Observable<PbkmVariant> {
        const params: HttpParams = new HttpParams()
            .set('agentNo', agentNo)
            .set('groupProposalRef', groupProposalRef)
            .set('groupPolicyRef', groupPolicyRef)
            .set('productType', productType);
        return this.http.get<PbkmVariant>(this.url + 'getPbkmVariant', {params});
    }

    public findPbkmVariantDto(agentNo: string, groupProposalRef: string, groupPolicyRef: string, productType: string): Observable<Variant> {
        const params: HttpParams = new HttpParams()
            .set('agentNo', agentNo)
            .set('groupProposalRef', groupProposalRef)
            .set('groupPolicyRef', groupPolicyRef)
            .set('productType', productType);
        return this.http.get<Variant>(this.url + 'getPbkmVariantDto', {params});
    }
}
