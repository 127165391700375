import { NgModule } from '@angular/core';
import { DictionaryPipe } from './dictionary.pipe';
import { TranslateWithDictionaryPipe } from './translate-with-dictionary.pipe';

@NgModule({
    declarations: [
        DictionaryPipe,
        TranslateWithDictionaryPipe
    ],
    providers: [
        DictionaryPipe,
        TranslateWithDictionaryPipe
    ],
    exports: [
        DictionaryPipe,
        TranslateWithDictionaryPipe
    ]
})

export class DictionaryModule {
}
