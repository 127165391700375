import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isEmpty } from 'lodash';

@Directive({
    selector: '[edValidatePesel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PeselValidatorDirective),
            multi: true
        }
    ]
})
export class PeselValidatorDirective implements Validator {

    private static checkPeselValidity(pesel: string): ValidationErrors | null {
        const hasProperFormat = /^(\d{11})$/.test(pesel);
        const wages = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
        let sum = 0;

        for (let i = 0; i < pesel.length - 1; i++) {
            const x = pesel.substr(i, 1);
            sum = sum + Number(x) * Number(wages[i]);
        }

        const calculatedChecksum = sum % 10;
        const checksum = Number(pesel.substr((pesel.length - 1), 1));
        const valid = (checksum === calculatedChecksum && hasProperFormat);

        if (valid) {
            return null;
        }

        return {pesel: true};
    }

    public validate(field: FormControl): ValidationErrors | null {
        if (isEmpty(field.value)) {
            return null;
        }

        return PeselValidatorDirective.checkPeselValidity(field.value);
    }
}
