<ng-container *ngIf="proposalChildrenUrlPath$ | async as currentUrlPath">
    <ng-container *ngIf="showProgressBar(currentUrlPath); else titleWithoutProgressBar">
        <div nxRow rowJustify="center" class="t-bg-soft-beige-light nx-margin-bottom-2m u-padding-lg">
            <div nxCol="12">
                <h1 headline="page"
                    class="u-text-center nx-margin-bottom-m t-primary-action-dark u-text-weight-bold">
                    {{'PROGRESS_BAR_LABEL.' + getTitlePage(currentUrlPath) | translate}}
                </h1>
                <ed-progress-bar [productCode]="productCode"
                                 [isConditionsChange]="isConditionsChange"
                                 [progressBarOptions]="progressBarOptions"
                                 [selectedProgressbarStepIndex]="getSelectedProgressbarStepIndex(currentUrlPath)"></ed-progress-bar>
            </div>
        </div>
    </ng-container>

    <ng-template #titleWithoutProgressBar>
        <ng-container [ngSwitch]="currentUrlPath">
            <div *ngSwitchCase="RouteUrl.SUMMARY" nxRow rowJustify="center"
                 class="t-bg-soft-beige-light nx-margin-bottom-2m u-padding-lg">
                <div nxCol="12">
                    <h1 headline="page"
                        class="u-text-center u-margin-bottom-0 t-primary-action-dark u-text-weight-bold">
                        {{'SUMMARY_PAGE.HEADER' | translate}}
                    </h1>
                </div>
            </div>
        </ng-container>
    </ng-template>
</ng-container>
