<ng-template #ngIfModalBody>
    <ng-content></ng-content>
</ng-template>
<nx-modal #basicModal
          (onModalClose)="close()"
          [modalBody]="ngIfModalBody"
          [showCloseIcon]="true"
          nxSize="fixed"
          *ngIf="modalOpen">
</nx-modal>
