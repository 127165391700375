import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';

import { StateService } from './state.service';
import { StorageKey } from '../enum';
import { Context } from '../model';

@Injectable({providedIn: 'root'})
export class ContextUrlService {

    private readonly contextQueryParamName = 'hash';

    constructor(private stateService: StateService,
                private router: Router) {
    }

    public getContextHashFromUrl(): string {
        const tree = this.getUrlTree();
        return tree.queryParamMap.get(this.contextQueryParamName);
    }

    public getContextQueryParam(): Params {
        const ctx = this.stateService.get(StorageKey.CONTEXT) as Context;
        return {[this.contextQueryParamName]: ctx.encryptedContext};
    }

    private getUrlTree(): UrlTree {
        const url = this.router.url;
        return this.router.parseUrl(url);
    }
}
