import { NxDateFormats } from '@aposin/ng-aquila/datefield';

export const dateFormats: NxDateFormats = {
    parse: {
        dateInput: 'YYYY-MM-DD'
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY-MM',
        dateA11yLabel: 'DD',
        monthYearA11yLabel: 'YYYY-MM'
    }
};
