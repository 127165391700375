import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class VersionHttpService {

    private readonly versionEndpoint = 'api/util/version';

    constructor(private http: HttpClient) {
    }

    public fetchVersion(): Observable<string> {
        // @ts-ignore
        return this.http.get<string>(this.versionEndpoint, {responseType: 'text'});
    }
}
