import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { isNil } from 'lodash';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { RouteUrl } from '@core/enum';
import { ContextService } from '@core/services';

import { ProgressBarStep } from './progress-bar-step';
import { fullProgressBarSteps } from './full-progress-bar-steps';
import { conditionsChangeBarSteps } from './conditions-change-bar-steps';
import { ProgressBarOptions } from './progress-bar-options';

@Injectable({providedIn: 'root'})
export class ProgressBarStepService {

    constructor(private router: Router,
                private contextService: ContextService) {
        this.router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                this.getProgressBarOptions$().subscribe();
            }
        });
    }

    public getProgressBarOptions$(): Observable<ProgressBarOptions> {
        return this.contextService.context$()
            .pipe(
                filter(ctx => !isNil(ctx)),
                map(context => {
                const progressBarOptions = new ProgressBarOptions();
                const isPbkmPolicy = !isNil(context.pbkmVariant);

                progressBarOptions.visibleHealthStatementStep = context.showHealthStatement;
                if (context.isConditionsChange) {
                    progressBarOptions.steps = conditionsChangeBarSteps;
                } else if (progressBarOptions.visibleHealthStatementStep) {
                    progressBarOptions.steps = fullProgressBarSteps;
                } else {
                    progressBarOptions.steps = this.getProgressBarStepsNotAvailableHealthStatement();
                }

                if (isPbkmPolicy) {
                    progressBarOptions.steps = this.getProgressBarStepsWithoutOffer(progressBarOptions.steps);
                }

                return progressBarOptions;
            }));
    }

    private getProgressBarStepsNotAvailableHealthStatement(): ProgressBarStep[] {
        return fullProgressBarSteps
            .filter((progressBarStep: ProgressBarStep) => progressBarStep.routeUrl !== RouteUrl.HEALTH_STATEMENT);
    }

    private getProgressBarStepsWithoutOffer(progressBar: ProgressBarStep[]): ProgressBarStep[] {
        return progressBar
            .filter((progressBarStep: ProgressBarStep) => progressBarStep.routeUrl !== RouteUrl.OFFER);
    }
}
