<nx-multi-stepper
    [linear]="true"
    currentStepLabel="Krok"
    (selectionChange)="selectionChangeHandle($event)"
    [selectedIndex]="selectedIndex">
    <nx-step *ngFor="let step of progressBarSteps"
             [label]="'PROGRESS_BAR_LABEL.' + step.label | translate"
             [completed]="false"
             [stepControl]="progressBarFormGroup.get(step.storageKey)"></nx-step>
</nx-multi-stepper>
