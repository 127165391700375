<ng-container edProvideParentForm>
    <div *ngIf="['N1', 'N2'].includes(consent.textType)">
        <h3 [nxHeadline]="consent.textType === 'N1' ? 'subsection-small' : 'subsection-xsmall'"
            class="nx-margin-bottom-2xs breakable-text consents-{{consent.textType}}">
            {{consent.text}}
        </h3>
        <div *ngIf="consent.secondaryConsents?.length">
            <ed-consent *ngFor="let secondaryConsentItem of consent.secondaryConsents"
                        [consent]="secondaryConsentItem"
                        [formSubmitted]="formSubmitted"
                        [readonlyMode]="readonlyMode"></ed-consent>
        </div>
    </div>

    <p *ngIf="consent.textType === 'TX'" class="font-size-lg breakable-text">{{consent.text}}</p>

    <p *ngIf="consent.textType === 'PT'"
       class="font-size-lg breakable-text consents-{{consent.textType}}">
        {{consent.text}}
    </p>

    <ng-container *ngIf="consent.textType === 'CH'"
                  [ngTemplateOutlet]="checkboxConsent"
                  [ngTemplateOutletContext]="{consentItem: consent}">
    </ng-container>

    <div *ngIf="consent.textType === 'CT' && consent.secondaryConsents?.length > 0"
         class="consents-{{consent.textType}}">
        <ed-check-all-consents [consent]="consent"
                               #checkAll
                               [readonlyMode]="readonlyMode"
                               [hidden]="consent.secondaryConsents.length === 1">
        </ed-check-all-consents>

        <ng-container
            *ngFor="let consentItem of consent.secondaryConsents"
            [ngTemplateOutlet]="checkboxConsent"
            [ngTemplateOutletContext]="{consentItem: consentItem, checkAll: checkAll}"></ng-container>
    </div>

    <ng-template let-consentItem="consentItem" let-checkAll="checkAll" #checkboxConsent>
        <div class="u-margin-bottom-sm consents-{{consentItem.textType}}"
             [ngClass]="{'nx-margin-left-2m': consent.secondaryConsents?.length > 1}">
            <nx-checkbox [(ngModel)]="consentItem.value"
                         #consentModel="ngModel"
                         (ngModelChange)="checkAll ? checkAll.changedChildCheckbox(consentItem.value) : null"
                         [name]="consent.type +'['+ consentItem.orderInSection +']'"
                         [trueRequired]="consentItem.required"
                         [disabled]="readonlyMode"
                         class="breakable u-margin-0 breakable-text">
                {{consentItem.text}}

                <nx-icon *ngIf="consentItem.tooltip"
                         [nxTooltip]="consentItem.tooltip"
                         name="info-circle">
                </nx-icon>
            </nx-checkbox>
            <nx-error *ngIf="consentModel.invalid && (consentModel.touched || formSubmitted)" appearance="text">
                <ed-error-message [element]="consentModel"></ed-error-message>
            </nx-error>
        </div>
    </ng-template>
</ng-container>
