import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import moment from 'moment';

@Directive({
    selector: '[edValidateProtectionStartDate]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProtectionStartDateValidator),
            multi: true
        }
    ]
})
export class ProtectionStartDateValidator implements Validator {

    public validate(field: FormControl): ValidationErrors | null {
        if (moment(field.value).date() !== 1) {
            return {PROTECTION_START_DATE_FIRST_DAY_ERROR_MESSAGE: true};
        }

        return null;
    }

}
