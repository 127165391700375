import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isEmpty } from 'lodash';

@Directive({
    selector: '[edValidateEmail]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EmailValidatorDirective),
            multi: true
        }
    ]
})
export class EmailValidatorDirective implements Validator {

    public static checkValidity(value: string): ValidationErrors | null {
        if (isEmpty(value)) {
            return null;
        }

        let isValid = false;
        if (value.length > 100) {
            isValid = false;
        } else {
            isValid = /^[_a-zA-Z0-9]+([.-][_a-zA-Z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,100})$/.test(value);
        }

        if (!isValid) {
            return {
                email: true
            };
        }

        return null;
    }

    public validate(field: FormControl): ValidationErrors | null {
        return EmailValidatorDirective.checkValidity(field.value);
    }

}
