<h2 nxModalTitle [status]="data.status">{{ data.title }}</h2>
<div nxModalContent>
    <p nxCopytext class="nx-modal-margin-bottom">
        {{ data.textLabel | translate }}
    </p>
</div>
<div nxModalActions class="status-action">
    <button *ngIf="!!data.cancelButton"
        class="nx-margin-bottom-0 nx-margin-right-xs"
        nxButton="small secondary"
        type="button"
        (click)="close(false)"
    >
        {{ data.cancelButton | translate }}
    </button>
    <button *ngIf="!!data.okButton"
        class="nx-margin-bottom-0"
        nxButton="small"
        type="button"
        (click)="close(true)"
    >
        {{  data.okButton | translate }}
    </button>
</div>