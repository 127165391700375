import { DocumentType, Gender } from '@core/enum';
import { DateType } from '@core/model';

export class Insured {
    public certificateRef: string;
    public firstName: string;
    public surname: string;
    public pesel: string;
    // fixme CVA
    public birthDate: DateType;
    public childBirthDate: DateType;
    public gender: Gender;
    public nationality: string[] = [];
    public documentType: DocumentType;
    public documentNo: string;
    public isMain: boolean;
    public relationshipToEmployee: string;
    public documentExpiryDate: DateType;
    public countryOfBirth: string;
}
