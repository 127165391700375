import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { CommonModule } from '@angular/common';

import { ErrorHandlerInterceptor } from './error-hander-interceptor.service';
import { ErrorMessagesComponent } from './error-messages/error-messages.component';

@NgModule({
    imports: [
        CommonModule,
        NxMessageModule
    ],
    declarations: [
        ErrorMessagesComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: ErrorHandlerInterceptor
        }
    ],
    exports: [
        ErrorMessagesComponent
    ]
})
export class ErrorHandlerModule {
}
