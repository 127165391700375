import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {

    public get(key: string): string | null {
        const valueFromStorage = sessionStorage.getItem(key);
        return valueFromStorage ? JSON.parse(valueFromStorage) : null;
    }

    public set(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

}