import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ContextData } from './context-data';

@Injectable()
export class EntryWithContextService {

    constructor(private http: HttpClient) {
    }

    public findContextEntry(): Observable<ContextData> {
        return this.http.get<ContextData>('api/testdata');
    }
}



