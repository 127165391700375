import { Component, OnInit } from '@angular/core';
import { CustomerDataService, RouterEventHelperService } from '@core/services';
import { ApplicationEntryManagerService } from '@core/services/application-entry-manager.service';
import { environment } from '../environments/environment';



@Component({
    selector: 'ed-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor(private routerEventHelperService: RouterEventHelperService,
                private applicationEntryManagerService: ApplicationEntryManagerService,
                private customerDataService: CustomerDataService) {
    }

    public ngOnInit(): void {
        if (environment.strictValidations) {
            this.redirectToEntryRoute();
        }
        this.customerDataService.checkIfTicketIsStillValid();
    }

    public redirectToEntryRoute(): void {
        this.routerEventHelperService.observeFirstNavigatedEnd$()
            .subscribe(x => {
                if (!this.applicationEntryManagerService.currentRouteSameAsEntryRoute()) {
                    this.applicationEntryManagerService.checkContextAndRedirectToEntryRoute();
                }
            });
    }

}
