import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryEntry } from './dictionary-entry';

@Pipe({
    name: 'translateWithDictionary'
})
export class TranslateWithDictionaryPipe implements PipeTransform {

    public transform(value: string, dictionaries: DictionaryEntry[]): string {
        const findedDirectory: DictionaryEntry = dictionaries.find((entry: DictionaryEntry) => {
            return entry.key === value;
        });
        return findedDirectory.value;
    }
}
