export enum RouteUrl {
    START = 'start',
    SECURE = 'secure',
    LOGIN = 'login',
    OFFER = 'offer',
    HEALTH_STATEMENT = 'health-statement',
    PERSONAL_DATA = 'personal-data',
    ADDRESS = 'address',
    BENEFICIARY = 'beneficiary',
    CONSENTS = 'consents',
    SUMMARY = 'summary',
    PROPOSAL = 'proposal',
    SUCCESS = 'success',
    CONDITIONS_CHANGE = 'conditions-change',
    INSURER = 'insurer',
    INSURED = 'insured',
    INSURED_OFFER = 'insured-offer',
}
