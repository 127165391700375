import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, tap, takeUntil } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { environment } from '../../../environments/environment';

import { ContextService } from '@core/services';
import { InsuredType, LoadingStatus, RouteUrl } from '@core/enum';

import { ContextUrlService } from '@core/services';
import { ContainerRefDirective } from '@shared/container-ref.directive';
import { DictionaryService } from '@dictionary';

import { ContextLoaderService } from '../context-loader.service';

@Component({
    templateUrl: './start-page.component.html',
    styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit, OnDestroy {

    @ViewChild(ContainerRefDirective, {static: true})
    public containerRef: ContainerRefDirective;

    public readonly production = environment.production;
    public readonly InsuredType = InsuredType;

    public disabledSubmitButton$: Observable<boolean>;
    public insuredType$: Observable<InsuredType>;
    public isConditionsChange: boolean;

    private destroy$ = new Subject<void>();

    constructor(private router: Router,
                private contextLoaderService: ContextLoaderService,
                private contextService: ContextService,
                private contextUrlService: ContextUrlService,
                private activatedRoute: ActivatedRoute,
                private dictionaryService: DictionaryService) {
    }

    public ngOnInit(): void {
        this.listenDisabledSubmitButton();

        this.initialLoadContext();

        this.listenDictionaries();
        this.listenInsuredContext();
    }

    public isPbkmOffer(): boolean {
        return this.contextService.isPbkmOffer();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public goToNextPage(): void {
        this.router.navigate([this.isConditionsChange ? RouteUrl.CONDITIONS_CHANGE : RouteUrl.PROPOSAL], {
            queryParams: this.contextUrlService.getContextQueryParam()
        });
    }

    private initialLoadContext(): void {
        const paramMap: ParamMap = this.activatedRoute.snapshot.paramMap;
        this.contextLoaderService.initialLoadContext(paramMap);
    }

    private listenDisabledSubmitButton(): void {
        this.disabledSubmitButton$ = combineLatest(
            this.contextLoaderService.status$,
            this.dictionaryService.status$)
            .pipe(
                map((statuses: LoadingStatus[]) => {
                    return statuses.every(status => LoadingStatus.DONE === status);
                }),
                map(ready => !ready));
    }

    private listenInsuredContext(): void {
        this.insuredType$ = this.contextLoaderService.status$
            .pipe(
                filter(status => status === LoadingStatus.DONE),
                tap(() => this.isConditionsChange = this.contextService.isConditionsChangeOffer()),
                map(_ => isEmpty(this.contextService.getContextEmployee()) ? InsuredType.EMPLOYEE : InsuredType.FAMILY_MEMBER)
            );
    }

    private listenDictionaries(): void {
        this.contextLoaderService.status$
            .pipe(
                filter(status => status === LoadingStatus.DONE),
                takeUntil(this.destroy$))
            .subscribe(_ => {
                this.dictionaryService.fetchDictionaries();
            });
    }
}
