<nx-formfield [label]="'FIELD.VARIANT' | translate" edProvideParentForm>
    <nx-dropdown [(ngModel)]="variantCode"
                 required
                 #variantCodeModel="ngModel"
                 (valueChange)="selectVariantHandler($event)"
                 (blur)="onBlurHandler($event)"
                 name="variantCode"
                 [ignoreItemTruncation]="true"
                 [disabled]="disabled">
        <nx-dropdown-item *ngFor="let variant of variants"
                          [value]="variant.code">{{variant.description}}</nx-dropdown-item>
    </nx-dropdown>
    <nx-error appearance="text" nxFormfieldError>
        <ed-error-message [element]="variantCodeModel"></ed-error-message>
    </nx-error>
</nx-formfield>
