import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import moment from 'moment';

import { PeselUtils } from '@shared/pesel-util';
import { DateType } from '@core/model';

@Directive({
    selector: '[edValidatePeselCompatibleWithDate]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PeselCompatibleWithDateValidatorDirective),
            multi: true
        }
    ]
})
export class PeselCompatibleWithDateValidatorDirective implements Validator {

    @Input()
    private edValidatePeselCompatibleWithDate: DateType;

    private static compare(pesel: string, date: DateType): ValidationErrors | null {
        if (!PeselUtils.canComparePeselAndBirthDate(pesel, date)) {
            return null;
        }

        if (PeselUtils.comparePeselAndBirthDate(pesel, moment(date))) {
            return null;
        }

        return {peselNotCompatibleWithDate: true};
    }

    public validate(field: FormControl): ValidationErrors | null {
        return PeselCompatibleWithDateValidatorDirective.compare(
            field.value,
            this.edValidatePeselCompatibleWithDate
        );
    }

}
