import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InsuredType } from '@core/enum';
import { Consent, Consents } from '@core/model';
import { ContextService } from './context.service';

const CONSENTS_TYPES = [
    'additionalConsents',
    'informationClause',
    'marketingConsents',
    'maintenanceConsents',
    'statementsConsents',
    'powersOfAttorneyConsents',
    'authorizationConsents'];

@Injectable({providedIn: 'root'})
export class ConsentsHttpService {
    protected BASE_ADDRESS = 'api/consents';

    constructor(private http: HttpClient,
                private contextService: ContextService) {
    }

    public fetchLoginPageConsents(agentNo: string,
                                  groupPolicyRef: string,
                                  groupProposalRef: string,
                                  page: string): Observable<Consents> {
        return this.fetchConsents(this.createHttpParams(agentNo, groupPolicyRef, groupProposalRef, page));
    }

    public fetchConsentsPageConsents(agentNo: string,
                                     groupPolicyRef: string,
                                     groupProposalRef: string,
                                     page: string,
                                     insuredType: InsuredType): Observable<Consents> {
        return this.fetchConsents(this.createHttpParams(agentNo, groupPolicyRef, groupProposalRef, page, insuredType));
    }

    private createHttpParams(agentNo: string,
                             groupPolicyRef: string,
                             groupProposalRef: string,
                             page: string,
                             insuredType?: InsuredType): HttpParams {
        const httpParams = new HttpParams()
            .append('agentNo', agentNo)
            .append('groupPolicyRef', groupPolicyRef)
            .append('groupProposalRef', groupProposalRef)
            .append('page', page)
            .append('language', this.contextService.getLanguageParam());

        if (insuredType) {
            return httpParams
                .append('insuredType', insuredType);
        } else {
            return httpParams;
        }
    }

    private fetchConsents(params: HttpParams): Observable<Consents> {
        return this.http.get<Consents>(this.BASE_ADDRESS, {params})
            .pipe(
                map(consents => {
                    CONSENTS_TYPES
                        .filter(consentType => consents[consentType])
                        .forEach(consentType => this.updateConsentsForTextFields(consents[consentType]));
                    return consents;
                })
            );
    }

    private updateConsentsForTextFields(consent: Consent): void {
        if (['N1', 'N2', 'TX', 'PT'].includes(consent.textType)) {
            consent.consent = true;
        }
        if (consent.secondaryConsents && consent.secondaryConsents.length) {
            consent.secondaryConsents
                .forEach(consentItem => this.updateConsentsForTextFields(consentItem));
        }
    }
}
