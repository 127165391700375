<nx-icon [nxPopoverTriggerFor]="ref"
         nxPopoverTrigger="hover" size="s"
         nxFormfieldAppendix
         name="info-circle">
</nx-icon>
<nx-popover #ref>
    <span>
        <ng-content></ng-content>
    </span>
</nx-popover>
