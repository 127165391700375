import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { BufforList } from './buffor-list';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    private readonly bufforSize = 3;
    private readonly initialValue = [];

    private errorMessagesSubject = new BehaviorSubject<string[]>(this.initialValue);
    public errorMessages$: Observable<string[]> = this.errorMessagesSubject.asObservable();

    public catchHttpError(err: HttpErrorResponse): void {
        if (err.status === 401) {
            return;
        }

        this.catchError(this.getErrorMessage(err));
    }

    public catchError(errorMessage: string): void {
        const currentErrorMessageValue = this.errorMessagesSubject.getValue();
        const bufferList = new BufforList(currentErrorMessageValue, this.bufforSize);

        bufferList.addToList(errorMessage);

        const newErrorMessages = bufferList.getValue();
        this.errorMessagesSubject.next([...newErrorMessages]);
    }

    public deleteAll(): void {
        this.errorMessagesSubject.next([]);
    }

    public deleteForIndex(index: number): void {
        const errMsg = this.errorMessagesSubject.getValue();
        const bufferList = new BufforList(errMsg, this.bufforSize);

        bufferList.deleteForIndex(index);

        const newErrorMessages = bufferList.getValue();
        this.errorMessagesSubject.next([...newErrorMessages]);
    }

    private getErrorMessage(err: HttpErrorResponse): string {
        // FIXME
        return this.dedicatedServerErrorMessage(err) ? this.dedicatedServerErrorMessage(err) : err.message;
    }

    private dedicatedServerErrorMessage(err: HttpErrorResponse): string | null {
        if (err.error && err.error.message) {
            return err.error.message;
        }
        return null;
    }
}
