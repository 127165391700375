<table nxTable zebra>
    <thead>
    <tr nxTableRow>
        <th nxHeaderCell>
            {{'FIELD.NAME' | translate}}
        </th>
        <th nxHeaderCell>
            {{'FIELD.ALLOCATION' | translate}}
        </th>
        <th *ngIf="!readonlyMode" nxHeaderCell>
            {{'FIELD.ACTION' | translate}}
        </th>
    </tr>
    </thead>
    <tbody>
    <tr nxTableRow *ngFor="let beneficiary of rows;let i = index;">
        <ng-container [ngSwitch]="beneficiary.type">
            <td *ngSwitchCase="LegalType.BANK" nxTableCell>{{beneficiary.bankDetails.name}}</td>
            <td *ngSwitchCase="LegalType.PERSON" nxTableCell>
                {{beneficiary.personDetails.firstName}} {{beneficiary.personDetails.lastName}}
            </td>
            <td *ngSwitchCase="LegalType.LEGAL_PERSON" nxTableCell>
                {{beneficiary.legalPersonDetails.name}}
            </td>
        </ng-container>
        <td nxTableCell>{{beneficiary.allocation}} %</td>
        <td nxTableCell *ngIf="!readonlyMode">
            <nx-icon name="pen"
                     class="u-margin-right-sm"
                     (click)="actionHandler(TableActionType.EDIT,i)"
                     size="s"></nx-icon>
            <nx-icon name="trash" (click)="actionHandler(TableActionType.DELETE,i)" size="s"></nx-icon>
        </td>
    </tr>
    </tbody>
</table>
