<nx-checkbox-group [(ngModel)]="selectedCheckboxCodes"
                   name="selectedCheckboxCodes"
                   (blur)="onBlurHandler($event)">
    <nx-accordion variant="light" *ngFor="let dictionaryPackage of packages">
        <nx-expansion-panel [disabled]="isPackageNotAllowed(dictionaryPackage)">
            <nx-expansion-panel-header #expHeader>
                <nx-expansion-panel-title>
                    <nx-checkbox (checkboxChange)="changeSelectedPackagesHandler($event)"
                                 [value]="dictionaryPackage.code"
                                 (click)="onPackageCheckboxClick(dictionaryPackage.code, expHeader.isExpanded(), $event)"
                                 [disabled]="disabled || isPackageNotAllowed(dictionaryPackage)"
                                 class="u-margin-top-md">
                        <span headline="subsection-xsmall" class="break-word">{{dictionaryPackage.description}}
                            : {{dictionaryPackage.premium | currency:'PLN': 'symbol-narrow': '0.0-2'}}</span>
                    </nx-checkbox>
                </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div class="overflow-x">
                <div *ngIf="dictionaryPackage.packageConsent" nxRow rowJustify="between" rowAlignItems="center" class="u-full-width">
                    <div nxCol="10">
                        <nx-checkbox [value]="formatConsentCode(dictionaryPackage)"
                                     name="checkbox-consent"
                                     (checkboxChange)="changeSelectedPackageConsentHandler(dictionaryPackage.code, $event)"
                                     [disabled]="disabled || isPackageNotAllowed(dictionaryPackage) || !selectedCheckboxCodes?.includes(dictionaryPackage.code)"
                                     class="u-margin-top-md">
                            <span headline="subsection-xsmall" class="break-word">
                                {{ dictionaryPackage.packageConsent.description }}
                            </span>
                        </nx-checkbox>
                    </div>
                    <div nxCol="2">
                        <ed-popover>
                            {{'POPOVER.POLICY_PACKAGE_CONSENT' | translate}}
                        </ed-popover>
                    </div>
                </div>
                <table nxTable zebra class="nx-margin-bottom-m">
                    <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell>
                            {{'OFFER.PACKAGE_TABLE.HEADER.RISK_DESCRIPTION' | translate}}
                        </th>
                        <th nxHeaderCell>
                            {{'OFFER.PACKAGE_TABLE.HEADER.SUM_GROSS' | translate}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr nxTableRow *ngFor="let risk of dictionaryPackage.risks">
                        <td nxTableCell>{{risk.description}}</td>
                        <td nxTableCell>
                            {{risk.sumGross > 0 ? (risk.sumGross | currency:'PLN': 'symbol-narrow': '0.0-2') : ('UTILS.GC' | translate)}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </nx-expansion-panel>
    </nx-accordion>
</nx-checkbox-group>
