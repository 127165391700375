import { RouteUrl, StorageKey } from '@core/enum';
import { ProgressBarStep } from './progress-bar-step';

export const conditionsChangeBarSteps: ProgressBarStep[] = [
    {
        label: 'INSURER',
        routeUrl: RouteUrl.INSURER,
        storageKey: StorageKey.CONDITIONS_CHANGE_INSURER,
    },
    {
        label: 'INSURED',
        routeUrl: RouteUrl.INSURED,
        storageKey: StorageKey.CONDITIONS_CHANGE_INSURED,
    },
    {
        label: 'INSURED_OFFER',
        routeUrl: RouteUrl.INSURED_OFFER,
        storageKey: StorageKey.CONDITIONS_CHANGE_INSURED_OFFER,
    },
    {
        label: 'OFFER',
        routeUrl: RouteUrl.OFFER,
        storageKey: StorageKey.CONDITIONS_CHANGE_OFFER,
    }
];
