import { Component, Input } from '@angular/core';

import { Consent } from '@core/model';

@Component({
  selector: 'ed-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent {

  @Input()
  public consent: Consent;

  @Input()
  public formSubmitted: boolean;

  @Input()
  public readonlyMode = false;
}
