import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateService } from '@core/services';
import { StorageKey } from '@core/enum';
import { Context } from '@core/model';

import { DictionaryHttpService } from './dictionary-http.service';
import { Dictionary } from './dictionary';
import { DictionaryKey } from './dictionary-key';
import { DictionaryEntry } from './dictionary-entry';
import { LoadingStatusService } from '@shared/loading-status.service';
import { LoadingStatus } from '@core/enum/loading-status';


// fixme dziedziczenie z loading to za mocne wiazanie dla tego servisu
@Injectable({providedIn: 'root'})
export class DictionaryService extends LoadingStatusService {

    private dictionaries: Dictionary[];


    constructor(private translateService: TranslateService,
                private dictionaryHttpService: DictionaryHttpService,
                private stateService: StateService) {
        super();
    }

    public fetchDictionaries(): void {
        // fixme state Service
        const ctx: Context = this.stateService.get(StorageKey.CONTEXT);
        this.dictionaryHttpService.fetchDictionaries(ctx.productCode)
            .subscribe(dictionaries => {
                this.dictionaries = dictionaries;
                this.changeStatus(LoadingStatus.DONE);
            });
    }

    public get(dictionaryKey: DictionaryKey): DictionaryEntry[] {
        const found = this.dictionaries
            .find(dictionary => dictionary.type === dictionaryKey);
        return found.entries;
    }

    public translate(dictionaryKey: string): Observable<DictionaryEntry[]> {
        return this.translateService.get(dictionaryKey)
            .pipe(
                map((streetPrefixes: {[key: string]: string}) =>
                    Object.entries(streetPrefixes).map(([key, value]) => ({key, value})))
            );
    }
}
