export enum DictionaryKey {
    DOCUMENT_TYPE = 'DOCUMENT_TYPE',
    DOCUMENT_TYPE_FOR_MINOR_CHILD = 'DOCUMENT_TYPE_FOR_MINOR_CHILD',
    OCCUPATION_CATEGORY_EMPLOYEE = 'OCCUPATION_CATEGORY_EMPLOYEE',
    OCCUPATION_CATEGORY_FAMILY = 'OCCUPATION_CATEGORY_FAMILY',
    OCCUPATION_CATEGORY_CHILD = 'OCCUPATION_CATEGORY_CHILD',
    GENDER = 'GENDER',
    RELATIONSHIP_TO_EMPLOYEE = 'RELATIONSHIP_TO_EMPLOYEE',
    NATIONALITY = 'NATIONALITY',
    STREET_PREFIX = 'STREET_PREFIX',
    BANK = 'BANK',
    BIRTH_COUNTRY = 'BIRTH_COUNTRY',
}
