<header nx-header>
    <nx-header-brand>
        <nx-link>
            <a href="#">
                <figure nxFigure>
                    <img src="assets/allianz_logo.svg"
                         alt="Allianz Global Brand Logo" role="none">
                </figure>
            </a>
        </nx-link>
        <figure *ngIf="isPbkmOffer()" nxFigure>
            <img src="assets/pbkm_logo.png"
                 alt="Polski Bank Komórek Macierzystych" role="none"
                 class="nx-margin-left-2m u-full-width">
        </figure>
    </nx-header-brand>

    <nx-header-actions>
        <ed-language-select></ed-language-select>
    </nx-header-actions>
</header>
