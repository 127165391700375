import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ErrorHandlerService } from '../error-handler.service';

@Component({
    selector: 'ed-error-messages',
    templateUrl: './error-messages.component.html',
})
export class ErrorMessagesComponent implements OnInit {

    @ViewChild('errorMessagesWrapper', {read: ElementRef, static: true})
    public errorMessagesWrapper: ElementRef;

    public errorMessages$: Observable<string[]>;

    constructor(private errorHandlerService: ErrorHandlerService) {
    }

    public ngOnInit(): void {
        this.errorMessages$ = this.errorHandlerService.errorMessages$
            .pipe(tap(_ => {
                this.scrollToError();
            }));
    }

    public closeErrorMessage(index: number): void {
        this.errorHandlerService.deleteForIndex(index);
    }

    private scrollToError(): void {
        this.errorMessagesWrapper.nativeElement.scrollIntoView({behavior: 'smooth'});
    }
}
