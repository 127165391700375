import { DocumentType, Gender, InsuredPersonType } from '@core/enum';
import { Moment } from 'moment';

export class FamilyMember {
    public type: InsuredPersonType;
    public firstName: string;
    public lastName: string;
    // fixme komponent datepicker z CVA
    public birthDate: string | Moment;
    public pesel: string;
    public id: string;
    public gender: Gender;
    public documentType: DocumentType;
    public documentNo: string;
}
