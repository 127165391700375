import { Component, Input, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { isNil } from 'lodash';

import { ErrorMessageService } from '@shared/components/error-message/error-messages.service';


@Component({
    selector: 'ed-error-message',
    templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent implements OnDestroy {

    @Input('element')
    public set changeElement(value: NgModel | null) {
        if (value) {
            this.registerNgModel(value);
        } else {
            this.unregisterNgModel();
        }
    }

    public errMsg$: Observable<any>;

    private ngModel: NgModel;

    private destroy$ = new Subject<void>();

    constructor(private errorMessageService: ErrorMessageService) {
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    private registerNgModel(ngModel: NgModel): void {
        this.ngModel = ngModel;
        this.errMsg$ = this.ngModel.valueChanges
            .pipe(
                startWith(this.ngModel.value),
                takeUntil(this.destroy$),
                switchMap(_ => this.getErrorMessage())
            );

    }

    private getErrorMessage(): Observable<string | null> {

        if (this.isElementInvalid()) {
            const lastErrorName = Object.keys(this.ngModel.errors)
                .filter(name => !isNil(this.ngModel.errors[name]))
                .pop();
            return this.getMessageForOneError(lastErrorName);
        }
        return of(null);
    }

    private unregisterNgModel(): void {
        this.destroy$.next();
        this.ngModel = null;
    }

    private getMessageForOneError(lastErrorName: string): Observable<string> {
        return this.errorMessageService.getErrorMessage(lastErrorName, this.ngModel.errors);
    }

    private isElementInvalid(): boolean {
        return this.ngModel && this.ngModel.invalid;
    }
}
