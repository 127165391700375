import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ed-dialog',
    templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnDestroy {

    public closeClicked$: Observable<void>;
    public modalOpen = true;

    private destroy$ = new Subject<void>();
    private afterCloseResultSubject$ = new Subject<void>();

    constructor() {
        this.closeClicked$ = this.afterCloseResultSubject$.asObservable().pipe(takeUntil(this.destroy$));
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public close(): void {
        this.closeModal();
        this.afterCloseResultSubject$.next();
    }

    private closeModal(): void {
        this.modalOpen = false;
    }
}
