import { Component } from '@angular/core';

import { BaseTableComponent } from '@shared/base-table.component';
import { TableActionType } from '@core/enum';
// fixme zależności od feature
import { Beneficiary } from '../../../proposal/beneficiary/model';
import { LegalType } from '../../../proposal/beneficiary/enum';


@Component({
    selector: 'ed-beneficiary-table',
    templateUrl: './beneficiary-table.component.html',
})
export class BeneficiaryTableComponent extends BaseTableComponent<Beneficiary> {

    public readonly TableActionType = TableActionType;
    public readonly LegalType = LegalType;
}
