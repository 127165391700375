import { isEmpty, padStart } from 'lodash';
import { Gender } from '@core/enum';
import { Moment } from 'moment';
import { DateType } from '@core/model';

export class PeselUtils {

    private static PESEL_LENGTH = 11;

    // tslint:disable-next-line:cyclomatic-complexity
    public static extractBirthDate(pesel: string): string {
        let year = parseInt(pesel.substring(0, 2), 10);
        let month = parseInt(pesel.substring(2, 4), 10);
        const day = pesel.substring(4, 6);

        if (month > 80) {
            year += 1800;
            month = month - 80;
        } else if (month > 60) {
            year += 2200;
            month = month - 60;
        } else if (month > 40) {
            year += 2100;
            month = month - 40;
        } else if (month > 20) {
            year += 2000;
            month = month - 20;
        } else {
            year += 1900;
        }

        return `${year}-${padStart(month.toString(), 2, '0')}-${padStart(day.toString(), 2, '0')}`;
    }

    public static extractGender(pesel: string): Gender {
        return parseInt(pesel.substring(9, 10), 10) % 2 === 0 ? Gender.FEMALE : Gender.MALE;
    }

    public static canComparePeselAndBirthDate(pesel: string, date: DateType): boolean {
        return !isEmpty(date) && !isEmpty(pesel) && pesel.length === PeselUtils.PESEL_LENGTH;
    }

    public static comparePeselAndBirthDate(pesel: string, date: Moment): boolean {
        return PeselUtils.extractBirthDate(pesel) === date.format('YYYY-MM-DD');
    }

}
