<div nxRow class="t-bg-soft-beige-light nx-margin-bottom-2m u-padding-lg">
    <div nxCol="12">
        <h1 headline="page"
            class="u-text-center u-margin-bottom-0 t-primary-action-dark u-text-weight-bold">
            {{'START_PAGE.HEADER.1' | translate}}
        </h1>
        <h1 headline="page" class="u-text-center u-margin-0">
            {{(isConditionsChange ? 'CONDITIONS_CHANGE.' : '') +'START_PAGE.HEADER.2' | translate}}
        </h1>
    </div>
</div>

<div nxRow rowJustify="center">
    <div nxCol="12,11,10,8">
        <ed-error-messages></ed-error-messages>
    </div>
</div>

<div *ngIf="insuredType$ | async as insuredType" nxRow rowJustify="center" class="nx-margin-bottom-2m">
    <ng-container *ngIf="!isConditionsChange">
        <ng-container *ngIf="insuredType === InsuredType.EMPLOYEE;else familyMember">
            <div nxCol="11">
                <h5 class="u-text-center">{{'START_PAGE.LOGIN_INFO.HEADER' | translate}}</h5>
                <ul class="flex-column-center">
                    <li class="u-text-center">{{'START_PAGE.LOGIN_INFO.LIST_ITEM.1' | translate}}</li>
                    <li class="u-text-center">{{'START_PAGE.LOGIN_INFO.LIST_ITEM.2' | translate}}</li>
                    <li *ngIf="!isPbkmOffer()" class="u-text-center">{{'START_PAGE.LOGIN_INFO.LIST_ITEM.3' | translate}}</li>
                </ul>
            </div>
            <div *ngIf="!isPbkmOffer()" nxCol="11" class="u-margin-top-md">
                <h6 class="u-text-center u-margin-0">{{'START_PAGE.ADDITIONAL_INFO.HEADER' | translate}}</h6>
                <ul class="flex-column-center">
                    <li class="u-text-center">{{'START_PAGE.ADDITIONAL_INFO.LIST_ITEM' | translate}}</li>
                </ul>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #familyMember>
        <h4 class="nx-margin-bottom-m">{{'START_PAGE.JOINED_FAMILY_MEMBER_INFO' | translate}}</h4>

        <div nxCol="11">
            <h5 class="u-text-center">{{'START_PAGE.LOGIN_INFO.HEADER' | translate}}</h5>
            <ul class="flex-column-center">
                <li class="u-text-center">{{'START_PAGE.LOGIN_INFO.LIST_ITEM.1' | translate}}</li>
                <li class="u-text-center">{{'START_PAGE.LOGIN_INFO.LIST_ITEM.2' | translate}}</li>
            </ul>
        </div>
    </ng-template>
</div>


<div nxRow rowJustify="end">
    <div nxCol="12,12,4" class="t-border-rich-blue">
        <button (click)="goToNextPage()"
                nxButton="primary"
                [disabled]="disabledSubmitButton$ | async"
                type="button">
            {{'BUTTON.NEXT' | translate}}
        </button>
    </div>
</div>

<ed-entry-with-context></ed-entry-with-context>

<ng-template edContainerRef></ng-template>
