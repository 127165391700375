import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { isInteger, isNil, isString } from 'lodash';

@Directive({
    selector: '[edIntegerInput]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => IntegerInputValidatorDirective),
            multi: true
        }
    ]
})
export class IntegerInputValidatorDirective implements Validator {

    public static checkValidity(value: any): ValidationErrors | null {
        if (isString(value) || !isNil(value) && !isInteger(value)) {
            return {integer: true};
        }

        return null;
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        return IntegerInputValidatorDirective.checkValidity(c.value);
    }

}
