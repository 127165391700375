import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ProductCode } from '@core/enum';

import { Dictionary } from './dictionary';


@Injectable({providedIn: 'root'})
export class DictionaryHttpService {

    private readonly url = 'api/dictionaries';

    constructor(private http: HttpClient) {
    }

    public fetchDictionaries(productCode: ProductCode): Observable<Dictionary[]> {
        const params = new HttpParams()
            .append('productType', productCode);
        return this.http.get<Dictionary[]>(this.url, {params});
    }
}

