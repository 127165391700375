import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { Observable, of } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ErrorMessageService {

    constructor(private translate: TranslateService) {
    }

    public getErrorMessage(errorName: string, validationErrors: ValidationErrors): Observable<string> {
        if (this[errorName]) {
            return this.findDynamicErrorMsg(errorName, validationErrors);
        } else {
            return this.translate.get('ERROR.' + errorName);
        }
    }

    private findDynamicErrorMsg(errorName: string, validationErrors: ValidationErrors): Observable<string | null> {
        const findedCallback = this[errorName];
        if (findedCallback) {
            return findedCallback(validationErrors);
        } else {
            return of(null);
        }
    }

    private get min(): (obj: { min: { minValue: number } }) => Observable<string> {
        return ({min}: ValidationErrors) => {
            return this.translate.get('ERROR.min', {minValue: min.minValue});
        };
    }

    private get max(): (obj: { max: { maxValue: number } }) => Observable<string> {
        return ({max}: ValidationErrors) => {
            return this.translate.get('ERROR.max', {maxValue: max.maxValue});
        };
    }

    private get minDate(): (obj: {min: {minDate: number}}) => Observable<string> {
        return (min: ValidationErrors) =>
            this.translate.get('ERROR.minDate', {min: min.minDate});
    }

    private get maxDate(): (obj: {max: {maxDate: number}}) => Observable<string> {
        return (max: ValidationErrors) =>
            this.translate.get('ERROR.maxDate', {max: max.maxDate});
    }

    private get minlength(): (obj: { minlength: { requiredLength: number } }) => Observable<string> {
        return ({minlength}: ValidationErrors) => {
            return this.translate.get('ERROR.minlength', {minlength: minlength.requiredLength});
        };
    }

    private get maxlength(): (obj: { maxlength: { requiredLength: number } }) => Observable<string> {
        return ({maxlength}: ValidationErrors) => {
            return this.translate.get('ERROR.maxlength', {maxlength: maxlength.requiredLength});
        };
    }

    private get listMaxSize(): (obj: string) => Observable<string> {
        return (listMaxSize: string) => {
            return this.translate.get('ERROR.listMaxSize', listMaxSize);
        };
    }

    private get nxDatefieldMax(): (obj: any) => Observable<string> {
        return ({nxDatefieldMax}: ValidationErrors) => {
            const maxMoment: Moment = nxDatefieldMax.max;
            return this.translate.get('ERROR.nxDatefieldMax', {maxMoment: maxMoment.format('YYYY-MM-DD')});
        };
    }


    private get nxDatefieldMin(): (obj: any) => Observable<string> {
        return ({nxDatefieldMin}: ValidationErrors) => {
            const minMoment: Moment = nxDatefieldMin.min;
            return this.translate.get('ERROR.nxDatefieldMin', {minMoment: nxDatefieldMin.min.format('YYYY-MM-DD')});
        };
    }
}
