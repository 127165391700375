import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { TableActionType } from '@core/enum';
import { TableRowAction } from '@core/model';

// fixme przenieść do base/table
@Directive()
export abstract class BaseTableComponent<T> {

    @Input()
    public rows: T[];

    @Input()
    public readonlyMode = false;

    @Output()
    public action = new EventEmitter<TableRowAction>();

    public readonly TableActionType = TableActionType;

    public actionHandler(actionType: TableActionType, index: number): void {
        const tableRowAction: TableRowAction = { type: actionType, rowIndex: index };
        this.action.emit(tableRowAction);
    }
}
