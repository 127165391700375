import { NgModule } from '@angular/core';
import { NX_DATE_FORMATS, NxDatefieldModule, NxDatepickerIntl } from '@aposin/ng-aquila/datefield';

import { DatepickerIntlService } from './datepicker-intl.service';
import { dateFormats } from './date-format';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { DatePickerComponent } from './datepicker.component';
import { FormsModule } from '@angular/forms';

const MODULES = [
    NxDatefieldModule,
    NxMomentDateModule,
    FormsModule,
];

const COMPONENTS = [
    DatePickerComponent,
];

@NgModule({
    declarations: [
        ...COMPONENTS
    ],
    imports: [
        ...MODULES
    ],
    providers: [
        {provide: NxDatepickerIntl, useClass: DatepickerIntlService},
        {provide: NX_DATE_FORMATS, useValue: dateFormats},
    ],
    exports: [
        ...COMPONENTS,
        ...MODULES
    ]
})
export class DatepickerModule {
}
