import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, forwardRef, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line
    selector: '[listMaxSize]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ListMaxSizeValidatorDirective),
            multi: true
        }
    ]
})
export class ListMaxSizeValidatorDirective implements Validator {

    @Input()
    public listMaxSize = 0;

    public validate(c: AbstractControl): ValidationErrors | null {
        if (c.value && c.value.length > this.listMaxSize) {
            return {listMaxSize: this.listMaxSize};
        }
        return null;
    }
}
