import { Component, Input } from '@angular/core';

import { InsuredType, ProductCode } from '@core/enum';

import { InsuredWith } from '../../../proposal/offer/model';

@Component({
    selector: 'ed-total-cost',
    templateUrl: './total-cost.component.html',
})
export class TotalCostComponent {

    @Input()
    public totalCost: number;

    @Input()
    public dynamicSum: boolean;

    @Input()
    public productCode: ProductCode;

    @Input()
    public insuredType: InsuredType;

    @Input()
    public insuredWith: InsuredWith | null;

    public readonly ProductCode = ProductCode;
    public readonly InsuredWith = InsuredWith;
    public readonly InsuredType = InsuredType;
}
