import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Context } from '@core/model';

import { PolicyInfo } from './model/policy-info';

@Injectable({providedIn: 'root'})
export class PolicyInfoHttpService {

    public readonly policyInfoUrl = 'api/policy/policyInfo';

    constructor(private http: HttpClient) {
    }

    public fetchPolicyInfo(context: Partial<Context>): Observable<PolicyInfo> {
        let params: HttpParams = new HttpParams();
        if (context.proposalRef) {
            params = params.set('groupProposalRef', context.proposalRef);
        }
        if (context.policyRef) {
            params = params.set('groupPolicyRef', context.policyRef);
        }

        return this.http.get<PolicyInfo>(this.policyInfoUrl, {params});
    }
}



