import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, forwardRef, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line
    selector: '[trueRequired]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TrueRequiredValidatorDirective),
            multi: true
        }
    ]
})
export class TrueRequiredValidatorDirective implements Validator {

    @Input()
    public trueRequired = true;

    public validate(c: AbstractControl): ValidationErrors | null {
        if (this.trueRequired && (c.value !== true)) {
            return {trueRequired: true};
        }
        return null;
    }
}
