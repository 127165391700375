import { Injectable } from '@angular/core';

import { Offer, Package, Variant } from '../model';


@Injectable({providedIn: 'root'})
export class CalculateTotalCostService {

    public calculateSum(offer: Offer): number {
        return this.getVariantCost(offer.variant) + this.sumPackageCosts(offer.packages);
    }

    public getDynamicSum(offer: Offer): boolean {
        return offer.variant.dynamicSum;
    }

    private getVariantCost(variant: Variant): number {
        return (variant && variant.cost) ? variant.cost : 0;
    }

    private sumPackageCosts(packages: Package[]): number {
        const initialValue = 0;

        if (!Array.isArray(packages)) {
            return initialValue;
        } else {
            return packages.reduce((accumulatedCost, currentPackage) =>
                accumulatedCost + currentPackage.premium, initialValue);
        }
    }
}
