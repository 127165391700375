import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { isEmpty } from 'lodash';

@Directive({
    selector: '[edValidatePhoneNo]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneNoValidatorDirective),
            multi: true
        }
    ]
})
export class PhoneNoValidatorDirective implements Validator {

    private static checkValidity(value: string): ValidationErrors | null {
        const valid = /^\+?(48)?\s?\d{3}\s?\d{3}\s?\d{3}$/.test(value);

        if (valid) {
            return null;
        }

        return {phoneNo: true};
    }

    public validate(field: FormControl): ValidationErrors | null {
        if (isEmpty(field.value)) {
            return null;
        }

        return PhoneNoValidatorDirective.checkValidity(field.value);
    }

}
