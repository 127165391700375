import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { StartPageComponent } from './start-page/start-page.component';
import { SharedModule } from '@shared/shared.module';
import { ErrorHandlerModule } from '../error-handler/error-handler.module';
import { EntryWithContextComponent } from './entry-with-context/entry-with-context.component';
import { EntryWithContextService } from './entry-with-context/entry-with-context.service';

@NgModule({
    declarations: [
        StartPageComponent,
        EntryWithContextComponent,
    ],
    imports: [
        SharedModule,
        ErrorHandlerModule,
    ],
    providers: [
        EntryWithContextService,
    ],
    exports: [
        StartPageComponent
    ]
})
export class StartModule {
}
