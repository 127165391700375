import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { DateType } from '@core/model';
import moment, { Moment } from 'moment';
import { isNil } from 'lodash';

const DATE_FORMAT = 'YYYY-MM-DD';

@Directive({
    selector: '[edValidateDateRange]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateRangeValidatorDirective),
            multi: true
        }
    ]
})
export class DateRangeValidatorDirective implements Validator {
    @Input()
    private max: DateType;

    @Input()
    private min: DateType;

    public static checkDateRangeValidity(sourceDate: string, min: DateType, max: DateType): ValidationErrors | null {
        const endOfSourceDate = moment(sourceDate).endOf('day');
        const endOfMaxDay = moment(max).endOf('day');
        const startOfSourceDate = moment(sourceDate).startOf('day');
        const startOfMinDay = moment(min).startOf('day');

        if (DateRangeValidatorDirective.hasInvalidValue(sourceDate)) {
            return null;
        }

        if (DateRangeValidatorDirective.checkMin(min, startOfMinDay, endOfSourceDate)) {
            return {minDate: moment(min).format(DATE_FORMAT)};
        }

        if (DateRangeValidatorDirective.checkMax(max, startOfSourceDate, endOfMaxDay)) {
            return {maxDate: moment(max).format(DATE_FORMAT)};
        }

        return null;
    }

    public static hasInvalidValue(sourceDate: string): boolean {
        return isNil(sourceDate) || !moment(new Date(sourceDate)).isValid();
    }

    public static checkMin(min: DateType, startOfMinDay: Moment, endOfSourceDate: Moment): boolean {
        return !isNil(min) && !moment(startOfMinDay).isBefore(endOfSourceDate);
    }

    public static checkMax(max: DateType, startOfSourceDate: Moment, endOfMaxDay: Moment): boolean {
        return !isNil(max) && !moment(startOfSourceDate).isBefore(endOfMaxDay);
    }

    public validate(field: FormControl): ValidationErrors | null {
        return DateRangeValidatorDirective.checkDateRangeValidity(field.value, this.min, this.max);
    }
}
