import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxErrorModule } from '@aposin/ng-aquila/base';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NdbxIconModule } from '@allianz/ngx-ndbx/icon';
import { NxProgressStepperModule } from '@aposin/ng-aquila/progress-stepper';
import { NxTooltipModule } from '@aposin/ng-aquila/tooltip';

import { ContainerRefDirective } from '@shared/container-ref.directive';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { NotFoundPageComponent } from '@shared/components/not-found-page/not-found-page.component';
import { ProvideParentFormDirective } from '@shared/provide-parent-form.directive';
import { PopoverComponent } from '@shared/components/popover/popover.component';
import { CheckAllConsentsComponent } from '@shared/components/check-all-consents/check-all-consents.component';
import { ConsentComponent } from '@shared/components/consent/consent.component';
import { ErrorMessageComponent } from '@shared/components/error-message/error-message.component';
import { PackagesComponent } from '@shared/components/packages/packages.component';
import { VariantComponent } from '@shared/components/variant/variant.component';
import { TotalCostComponent } from '@shared/components/total-cost/total-cost.component';
import { ProgressBarComponent } from '@shared/components/page-header/progress-bar/progress-bar.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { PhoneNoValidatorDirective } from '@shared/validators/phone-no.validator';
import { ProtectionStartDateValidator } from '@shared/validators/protection-start-date.validator';
import { PeselValidatorDirective } from '@shared/validators/pesel.validator';
import { DocumentNoValidatorDirective } from '@shared/validators/document-no.validator';
import { EmailValidatorDirective } from '@shared/validators/email.validator';
import { DatepickerModule } from '@shared/datepicker/datepicker.module';
import { DateCompatibleWithPeselValidatorDirective } from '@shared/validators/date-compatible-with-pesel.validator';
import { TrueRequiredValidatorDirective } from '@shared/validators/true-required.validator';
import { ListMaxSizeValidatorDirective } from '@shared/validators/list-max-size.validator';
import { DictionaryModule } from '../dictionary/dictionary.module';
import { InputCapitalizeDirective } from '@shared/input-capitalize.directive';
import { IntegerInputValidatorDirective } from '@shared/validators/integer-input.validator';
import { RegonValidatorDirective } from '@shared/validators/regon.validator';
import { NipValidatorDirective } from '@shared/validators/nip.validator';
import { DateRangeValidatorDirective } from '@shared/validators/date-range.validator';
import { InputNumberRangeValidateDirective } from '@shared/validators/input-number-range.validate';
import { BeneficiaryTableComponent } from '@shared/components/beneficiary-table/beneficiary-table.component';
import { PeselToGenderValidatorDirective } from '@shared/validators/pesel-to-gender.validator';
import { LOADER_CONFIG } from './translate-http-loader';
import { PeselCompatibleWithDateValidatorDirective } from '@shared/validators/pesel-compatible-with-date.validator';
import { DateValidatorDirective } from './validators/date.validator';
import { PackagesValidatorDirective } from './validators/packages.validator';
import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';

const MODULES = [
    CommonModule,
    FormsModule,

    NxFormfieldModule,
    NxGridModule,
    NxPopoverModule,
    NxTableModule,
    NxButtonModule,
    NxInputModule,
    NxErrorModule,
    NxIconModule,
    NdbxIconModule,
    NxAccordionModule,
    NxCheckboxModule,
    NxTooltipModule,
    NxHeadlineModule,
    NxDropdownModule,
    NxProgressStepperModule,
    NxListModule,

    DictionaryModule,
    DatepickerModule,
];

const COMPONENTS = [
    DialogComponent,
    NotFoundPageComponent,
    PopoverComponent,
    ErrorMessageComponent,
    CheckAllConsentsComponent,
    ConsentComponent,
    BeneficiaryTableComponent,
    PackagesComponent,
    VariantComponent,
    TotalCostComponent,
    ProgressBarComponent,
    PageHeaderComponent,
    StatusDialogComponent,
];

const DIRECTIVES = [
    ContainerRefDirective,
    ProvideParentFormDirective,
    PhoneNoValidatorDirective,
    ProtectionStartDateValidator,
    PeselValidatorDirective,
    PeselToGenderValidatorDirective,
    DocumentNoValidatorDirective,
    EmailValidatorDirective,
    TrueRequiredValidatorDirective,
    ListMaxSizeValidatorDirective,
    DateCompatibleWithPeselValidatorDirective,
    PeselCompatibleWithDateValidatorDirective,
    InputCapitalizeDirective,
    IntegerInputValidatorDirective,
    RegonValidatorDirective,
    NipValidatorDirective,
    DateRangeValidatorDirective,
    DateValidatorDirective,
    InputNumberRangeValidateDirective,
    PackagesValidatorDirective,
];

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...DIRECTIVES,
    ],
    imports: [
        ...MODULES,
        NxModalModule,
        ReactiveFormsModule,
        TranslateModule.forChild(LOADER_CONFIG),
    ],
    providers: [DatePipe],
    exports: [
        ...MODULES,
        ...COMPONENTS,
        ...DIRECTIVES,
        TranslateModule,
    ]
})
export class SharedModule {
}
