import { Component, Inject } from '@angular/core';
import { NxStatusIconType } from '@aposin/ng-aquila/icon';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';

interface DialogData {
    okButton: string;
    cancelButton: string;
    textLabel: string;
    status: NxStatusIconType;
    title: string;
}

@Component({
    selector: 'ed-status-dialog',
    templateUrl: './status-dialog.component.html',
})
export class StatusDialogComponent {

    constructor(@Inject(NX_MODAL_DATA) public data: DialogData,
                public dialogRef: NxModalRef<StatusDialogComponent>) { }

    public close(result: boolean): void {
        this.dialogRef?.close(result);
    }
}
