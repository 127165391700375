import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Event, NavigationEnd, Router, RouterEvent, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { filter, first, map, startWith } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class RouterEventHelperService {

    constructor(private router: Router) {
    }

    public observeFirstNavigatedEnd$(): Observable<string> {
        return this.router.events
            .pipe(
                filter((routerEvent: Event) => routerEvent instanceof NavigationEnd),
                map((navigationEnd: NavigationEnd) => this.mapFromUrl(navigationEnd)),
                map(url => this.mapRouteUrl(url)),
                first());
    }

    public observeProposalChildrenUrlPath$(): Observable<string> {
        return this.router.events
            .pipe(
                filter((routerEvent: Event) => routerEvent instanceof NavigationEnd),
                map((navigationEnd: NavigationEnd) => this.mapFromUrl(navigationEnd)),
                startWith(this.getInitialUrlWhenNavigatedEndNotSupported()),
                map(url => this.mapProposalRouteUrl(url)),
            );
    }

    private mapFromUrl(navigationEnd: NavigationEnd): string {
        return navigationEnd.urlAfterRedirects;
    }

    private mapRouteUrl(url: string): string {
        const tree = this.router.parseUrl(url);
        const urlSegment: UrlSegment = this.getPrimaryUrlSegment(tree);
        return urlSegment.path;
    }

    private mapProposalRouteUrl(url: string): string {
        const tree = this.router.parseUrl(url);
        const urlSegment: UrlSegment = this.getSecondaryUrlSegment(tree);
        return urlSegment.path;
    }

    private getPrimaryUrlSegment(urlTree: UrlTree): UrlSegment {
        return this.getUrlSegmentGroup(urlTree).segments[0];
    }

    private getSecondaryUrlSegment(urlTree: UrlTree): UrlSegment {
        return this.getUrlSegmentGroup(urlTree).segments[1];
    }

    private getUrlSegmentGroup(urlTree: UrlTree): UrlSegmentGroup {
        return urlTree.root.children.primary;
    }

    private getInitialUrlWhenNavigatedEndNotSupported(): string {
        return this.router.routerState.snapshot.url;
    }
}
