import { Employee, Offer } from '@core/model';
import { InsuredType, PbkmVariant } from '@core/enum';

import { AdultFamilyMember } from './adult-family-member';
import { FamilyMember } from './family-member';
import { EmploymentData } from './employment-data';
import { InsuredWith } from './insured-with';

export class OfferPage {
    public insuredType: InsuredType;
    public employmentData: EmploymentData;
    public employeeData: Employee;
    public insuredWith: InsuredWith;
    public offer: Offer;
    public adultFamilyMembers: AdultFamilyMember[];
    public familyMembers: FamilyMember[];
    public pbkmVariant: PbkmVariant;

    public static create(): OfferPage {
        return new OfferPage();
    }
}
