import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { LoadingStatusService } from '@shared/loading-status.service';
import { Context } from '@core/model';
import { LoadingStatus } from '@core/enum';
import { ErrorHandlerService } from '../error-handler';

import { ContextStorageService } from './context-storage.service';
import { ContextUrlLoaderService } from './context-url-loader.service';
import { EMPTY_CONTEXT_ERROR_MESSAGE } from './empty-context-error-message';

@Injectable({providedIn: 'root'})
export class ContextLoaderService extends LoadingStatusService {

    constructor(private translate: TranslateService,
                private contextStorageService: ContextStorageService,
                private errorHandlerService: ErrorHandlerService,
                private contextUrlLoaderService: ContextUrlLoaderService) {
        super();
    }

    public initialLoadContext(paramMap: ParamMap): void {
        this.contextStorageService.clear();
        this.initContext(paramMap);
    }

    private initContext(paramMap: ParamMap): void {
        // fixme SRP
        this.changeStatus(LoadingStatus.LOADING);

        const loaded: boolean = this.contextStorageService.hasContextFromLocalStorage();
        if (loaded) {
            // domyslnie pobrane jest z localstorage
            this.changeStatus(LoadingStatus.DONE);
        } else {
            this.loadContexFormUrl(paramMap);
        }
    }

    private loadContexFormUrl(paramMap: ParamMap): void {
        const encryptedContext = paramMap.get('hash');
        if (encryptedContext) {
            this.contextUrlLoaderService.loadContext(encryptedContext)
                .subscribe((ctx: Context) => {
                    this.saveContextToLocalStorage(ctx);
                    this.changeStatus(LoadingStatus.DONE);
                });
        } else {
            this.changeStatus(LoadingStatus.ERROR);
            this.translate.get('ERROR.' + EMPTY_CONTEXT_ERROR_MESSAGE)
                .subscribe(error => this.errorHandlerService.catchError(error));
        }
    }

    private saveContextToLocalStorage(context: Context): void {
        this.contextStorageService.save(context);
    }
}
