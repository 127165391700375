export class BufforList {

    constructor(private list: string[], private bufforSize: number) {
    }

    public deleteForIndex(index: number): void {
        this.list.splice(index, 1);
    }

    public getValue(): string[] {
        return this.list;
    }

    public addToList(msg: string): void {
        this.deleteMessageIfExisSameAs(msg);
        this.deleteMessageIfFull();

        this.addMessageToFirstPosition(msg);
    }

    private deleteMessageIfExisSameAs(msg): void {
        if (this.messageExistOnIndex(msg) >= 0) {
            const errorMsgIndex = this.messageExistOnIndex(msg);
            this.deleteForIndex(errorMsgIndex);
        }
    }

    private deleteMessageIfFull(): void {
        if (this.isFull()) {
            this.deleteLast();

        }
    }

    private addMessageToFirstPosition(msg: string): void {
        this.list.unshift(msg);
    }

    private messageExistOnIndex(msg: string): number {
        return this.list.indexOf(msg);
    }

    private deleteLast(): void {
        const lastItemIndex = this.list.length - 1;
        this.list.splice(lastItemIndex, 1);
    }

    private isFull(): boolean {
        return this.bufforSize === this.list.length;
    }
}
