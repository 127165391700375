import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteUrl } from '@core/enum';
import { NotFoundPageComponent } from '@shared/components/not-found-page/not-found-page.component';
import { SecureInitializationComponent } from './secure-initalization.component';
import { StartPageComponent } from './start/start-page/start-page.component';
import { SuccessPageComponent } from './success-page/success-page.component';


const routes: Routes = [
    {
        path: RouteUrl.SECURE,
        component: SecureInitializationComponent
    },
    {
        path: '',
        redirectTo: RouteUrl.START,
        pathMatch: 'full'
    },
    {
        path: RouteUrl.START,
        component: StartPageComponent,
    },
    {
        path: RouteUrl.START + '/:hash',
        component: StartPageComponent,
    },
    {
        path: RouteUrl.CONDITIONS_CHANGE,
        loadChildren: () => import('./conditions-change/conditions-change.module').then(m => m.ConditionsChangeModule),
    },
    {
        path: RouteUrl.PROPOSAL,
        loadChildren: () => import('./proposal/proposal.module').then(m => m.ProposalModule),
    },
    {
        path: RouteUrl.SUCCESS,
        component: SuccessPageComponent,
    },
    {
        path: '**',
        component: NotFoundPageComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
