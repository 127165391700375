import { RouteUrl, StorageKey } from '@core/enum';
import { ProgressBarStep } from './progress-bar-step';

export const fullProgressBarSteps: ProgressBarStep[] = [
    {
        label: 'OFFER',
        routeUrl: RouteUrl.OFFER,
        storageKey: StorageKey.OFFER,
    },
    {
        label: 'HEALTH_STATEMENT',
        routeUrl: RouteUrl.HEALTH_STATEMENT,
        storageKey: StorageKey.HEALTH_STATEMENT,
    },
    {
        label: 'PERSONAL_DATA',
        routeUrl: RouteUrl.PERSONAL_DATA,
        storageKey: StorageKey.PERSONAL_DATA,
    },
    {
        label: 'ADDRESS',
        routeUrl: RouteUrl.ADDRESS,
        storageKey: StorageKey.ADDRESS,
    },
    {
        label: 'BENEFICIARY',
        routeUrl: RouteUrl.BENEFICIARY,
        storageKey: StorageKey.BENEFICIARY,
    },
    {
        label: 'CONSENTS',
        routeUrl: RouteUrl.CONSENTS,
        storageKey: StorageKey.CONSENTS,
    }
];
