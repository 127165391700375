import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NxDatefieldDirective } from '@aposin/ng-aquila/datefield';
import moment from 'moment';
import { Subject } from 'rxjs';

import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

const DEBOUNCE_TIME = 50;

@Component({
    selector: 'ed-date-picker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatePickerComponent implements AfterViewInit, OnDestroy {
    @Input()
    public max: Date;
    @Input()
    public min: Date;
    @Input()
    public defaultStart: Date;
    @Input()
    public dateFilter: () => void;
    @Input()
    public disabled: boolean;
    @Output()
    public dateChange = new EventEmitter<string>();

    @Input()
    private formattedDate: NgModel;
    @ViewChild('dateHolderInput', {static: true})
    private dateHolderInput: NxDatefieldDirective<Date>;

    public dateHolder = null;

    private destroy$ = new Subject();

    constructor(private datePipe: DatePipe) {
    }

    public ngAfterViewInit(): void {
        if (this.formattedDate) {
            this.formattedDate.valueChanges
                .pipe(
                    takeUntil(this.destroy$),
                    distinctUntilChanged(),
                    debounceTime(DEBOUNCE_TIME),
                    map((value: string | moment.Moment) => moment(value, 'yyyy-MM-dd').toDate()),
                    map(value => value.toString() === 'Invalid Date' ? null : value),
                )
                .subscribe(value => this.dateHolder = value);
        }

        this.dateHolderInput.dateChange
            .pipe(
                takeUntil(this.destroy$),
                distinctUntilChanged(),
                debounceTime(DEBOUNCE_TIME),
                map(event => event.value)
            )
            .subscribe(value => this.dateChange.emit(this.datePipe.transform(value, 'y-MM-dd')));
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public startAt(): Date {
        if (this.formattedDate && this.formattedDate.value) {
            return this.formattedDate.value as Date;
        } else if (this.defaultStart) {
            return this.defaultStart;
        }

        return new Date();
    }
}
