import { Component } from '@angular/core';

import { ContextService } from '@core/services';

@Component({
    selector: 'ed-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    constructor(private contextService: ContextService) {
    }

    public isPbkmOffer(): boolean {
        return this.contextService.isPbkmOffer();
    }
}
