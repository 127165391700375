import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ProductCode, RouteUrl } from '@core/enum';
import { ContextService, RouterEventHelperService } from '@core/services';

import { ProgressBarService } from './progress-bar.service';
import { ProgressBarStepService } from './progress-bar-step.service';
import { ProgressBarOptions } from './progress-bar-options';

@Component({
    selector: 'ed-page-header',
    templateUrl: './page-header.component.html',
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    @Input()
    public isConditionsChange: boolean;
    public readonly RouteUrl = RouteUrl;
    public progressBarOptions: ProgressBarOptions;
    public proposalChildrenUrlPath$: Observable<string>;
    public productCode: ProductCode;
    private destroy$ = new Subject<void>();

    constructor(private routerHelperService: RouterEventHelperService,
                private translate: TranslateService,
                private progressBarService: ProgressBarService,
                private progressBarStepService: ProgressBarStepService,
                private contextService: ContextService) {
    }

    public ngOnInit(): void {
        this.productCode = this.contextService.getProductCode();
        this.progressBarStepService.getProgressBarOptions$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(progressBarOptions => {
                this.progressBarOptions = progressBarOptions;
            });
        this.proposalChildrenUrlPath$ = this.routerHelperService.observeProposalChildrenUrlPath$();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public getTitlePage(currentUrl: string): string {
        const step = this.progressBarService.getSelectedProgressbarStep(this.progressBarOptions.steps, currentUrl);
        return step.label;
    }

    public showProgressBar(currentUrl: string): boolean {
        return this.progressBarService.showProgressBar(this.progressBarOptions.steps, currentUrl);
    }

    public getSelectedProgressbarStepIndex(currentUrl: string): number {
        return this.progressBarService.getSelectedProgressbarStepIndex(this.progressBarOptions.steps, currentUrl);
    }
}
