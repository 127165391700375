import { ControlContainer, NgForm } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[edProvideParentForm]',
    providers: [
        {
            provide: ControlContainer,
            useFactory: (form: NgForm) => {
                return form;
            },
            deps: [NgForm]
        }
    ]
})
export class ProvideParentFormDirective {
}
