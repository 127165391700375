import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';
import { isEmpty } from 'lodash';
import moment from 'moment';

@Directive({
    selector: '[edValidateDate]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateValidatorDirective),
            multi: true
        }
    ]
})
export class DateValidatorDirective implements Validator {

    public static checkDateValidity(date: string): boolean {
        return moment(date, 'YYYY-MM-DD', true).isValid();
    }

    public validate(field: UntypedFormControl): {nxDatefieldParse: string} | null {
        let isValid = false;

        if (isEmpty(field.value)) {
            isValid = true;
        } else {
            isValid = DateValidatorDirective.checkDateValidity(field.value as string);
        }

        if (!isValid) {
            return {nxDatefieldParse: 'nxDatefieldParse'};
        }

        return null;
    }
}

