import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { VersionHttpService } from '@core/http/version-http.service';

@Component({
    selector: 'ed-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

    public version$: Observable<string>;

    constructor(private versionHttpService: VersionHttpService) {
    }

    public ngOnInit(): void {
        this.version$ = this.versionHttpService.fetchVersion();
    }
}
