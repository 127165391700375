import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isEmpty } from 'lodash';
import { PeselUtils } from '@shared/pesel-util';

@Directive({
    selector: '[edValidatePeselToGender]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PeselToGenderValidatorDirective),
            multi: true
        }
    ]
})
export class PeselToGenderValidatorDirective implements Validator {
    @Input()
    private pesel: string;

    public static checkPeselToGenderValidity(gender: string, pesel: string): ValidationErrors | null {
        const genderFromPesel = PeselUtils.extractGender(pesel);
        if (gender.valueOf() === genderFromPesel) {
            return null;
        } else {
            return {gender: true};
        }
    }

    public validate(field: FormControl): ValidationErrors | null {
        if (isEmpty(field.value) || isEmpty(this.pesel)) {
            return null;
        }
        return PeselToGenderValidatorDirective.checkPeselToGenderValidity(field.value, this.pesel);
    }

}
