import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { DecryptedContext } from './model/decrypted-context';


@Injectable({providedIn: 'root'})
export class DecryptContextHttpService {

    public readonly decryptUrl = 'api/context/decrypt/';

    constructor(private http: HttpClient) {
    }

    public decrypt(hash: string): Observable<DecryptedContext> {
        return this.http.get<DecryptedContext>(this.decryptUrl + hash);
    }
}
