import { Component, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash';

import { Consent } from '@core/model';


@Component({
    selector: 'ed-check-all-consents',
    templateUrl: './check-all-consents.component.html',
    styleUrls: ['./check-all-consents.component.scss']
})
export class CheckAllConsentsComponent implements OnInit {
    @Input()
    public consent: Consent;
    @Input()
    public readonlyMode: boolean;

    public ngOnInit(): void {
        this.changedChildCheckbox(true);
    }

    public changedCheckbox(checked: boolean): void {
        this.markSecondaryConsents(checked);
    }

    public changedChildCheckbox(value: boolean): void {
        if (value) {
            this.consent.value = !this.checkIfIsUnchecked();
        } else {
            this.consent.value = false;
        }
    }

    public checkIfIsUnchecked(): boolean {
        return this.consent.secondaryConsents
            .some(checkboxConsent => checkboxConsent.value === false
                || isNil(checkboxConsent.value));
    }

    private markSecondaryConsents(checked: boolean): void {
        this.consent.secondaryConsents
            .forEach(secondaryConsent => secondaryConsent.value = checked);
    }

}
