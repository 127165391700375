import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NxStepComponent } from '@aposin/ng-aquila/progress-stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

import { ProductCode, RouteUrl, StorageKey } from '@core/enum';
import { ContextUrlService, StateService } from '@core/services';

import { ProgressBarStep } from '../progress-bar-step';
import { ProgressBarOptions } from '../progress-bar-options';

@Component({
    selector: 'ed-progress-bar',
    templateUrl: './progress-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements AfterViewInit, OnDestroy {
    @Input()
    public productCode: ProductCode;
    @Input()
    public isConditionsChange: boolean;

    @Input('progressBarOptions')
    public set changeProgressBarOptions(progressBarOptions: ProgressBarOptions) {
        if (!this.progressBarFormGroup) {
            this.initProgressBarFormGroup();
        }
        this.progressBarSteps = progressBarOptions.steps;
        this.updateProgressBarFormGroup(progressBarOptions.visibleHealthStatementStep);
        this.unsubscribeObservables();
        this.subscribeToStoragePages(progressBarOptions.steps);
    }

    public progressBarSteps: ProgressBarStep[];

    @Input()
    public set selectedProgressbarStepIndex(value: number) {
        this.selectedIndex = value;
    }

    @ViewChildren(NxStepComponent)
    public nxStepsCreatedAfterViewInit: QueryList<NxStepComponent>;

    private readonly parentRoutePrefix = '/' + RouteUrl.PROPOSAL;
    private readonly conditionsChangeRoutePrefix = '/' + RouteUrl.CONDITIONS_CHANGE;
    public progressBarFormGroup: FormGroup;
    public selectedIndex: number;
    private destroy$ = new Subject<void>();

    constructor(private router: Router,
                private fb: FormBuilder,
                private stateService: StateService,
                private contextUrlService: ContextUrlService,
                private cdr: ChangeDetectorRef) {
    }

    public ngAfterViewInit(): void {
        this.updateStepInteacted();
    }

    public ngOnDestroy(): void {
        this.unsubscribeObservables();
    }

    public selectionChangeHandle(nx: StepperSelectionEvent): void {
        this.router.navigate([this.isConditionsChange ? this.conditionsChangeRoutePrefix : this.parentRoutePrefix,
            this.getRouteUrl(nx.selectedIndex)], {
            queryParams: this.contextUrlService.getContextQueryParam()
        });
    }

    private getRouteUrl(progressBarStepIndex: number): RouteUrl {
        return this.progressBarSteps[progressBarStepIndex].routeUrl;
    }

    private setNxStepInteracted(): void {
        this.nxStepsCreatedAfterViewInit.forEach(step => step.interacted = true);
    }

    private initProgressBarFormGroup(): void {
        const initialvalue = null;
        type formGroupKeysSameAsStorageKeyValues = { [key in StorageKey]: any };
        // @ts-ignore
        const formGroup: formGroupKeysSameAsStorageKeyValues = this.isConditionsChange ? {
            conditionsChangeInsurerPage: [initialvalue, Validators.required],
            conditionsChangeInsuredPage: [initialvalue, Validators.required],
            conditionsChangeInsuredOfferPage: [initialvalue, Validators.required],
            conditionsChangeOfferPage: [initialvalue, Validators.required],
        } : {
            offerPage: [initialvalue, Validators.required],
            personalDataPage: [initialvalue, Validators.required],
            addressPage: [initialvalue, Validators.required],
            beneficiaryPage: [initialvalue, Validators.required],
            consentsPage: [initialvalue, Validators.required]
        };

        this.progressBarFormGroup = this.fb.group(formGroup);
    }

    private subscribeToStoragePages(progressBarSteps: ProgressBarStep[]): void {
        progressBarSteps.forEach(progressBarStep => {
            const storageKey = progressBarStep.storageKey;
            this.stateService.observe(storageKey)
                .pipe(
                    takeUntil(this.destroy$))
                .subscribe(page => {
                    this.progressBarFormGroup.get(storageKey).patchValue(page);
                    this.updateStepInteacted();
                });
        });
    }

    private updateStepInteacted(): void {
        if (this.nxStepsCreatedAfterViewInit) {
            this.setNxStepInteracted();
            this.cdr.detectChanges();
        }
    }

    private unsubscribeObservables(): void {
        this.destroy$.next();
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private updateProgressBarFormGroup(currentVisibleHealthStatementStep: boolean): void {
        const initialvalue = null;
        const existHealthStatementPageControl = !!this.progressBarFormGroup.get('healthStatementPage');
        if (currentVisibleHealthStatementStep && !existHealthStatementPageControl) {
            this.progressBarFormGroup.addControl('healthStatementPage', new FormControl(initialvalue, [Validators.required]));
        } else if (!currentVisibleHealthStatementStep && existHealthStatementPageControl) {
            this.progressBarFormGroup.removeControl('healthStatementPage');
        }
    }
}
