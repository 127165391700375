import { Injectable } from '@angular/core';
import { ProgressBarStep } from './progress-bar-step';


@Injectable({providedIn: 'root'})
export class ProgressBarService {

    public showProgressBar(progressBarSteps: ProgressBarStep[], currentUrl: string): boolean {
        return progressBarSteps.length
            && progressBarSteps
                .some((progressBarStep: ProgressBarStep) => progressBarStep.routeUrl === currentUrl);
    }

    public getSelectedProgressbarStepIndex(progressBarSteps: ProgressBarStep[], currentUrl: string): number {
        return progressBarSteps
            .findIndex(progressBarStep => progressBarStep.routeUrl === currentUrl);
    }

    public getSelectedProgressbarStep(progressBarSteps: ProgressBarStep[], currentUrl: string): ProgressBarStep {
        const index = this.getSelectedProgressbarStepIndex(progressBarSteps, currentUrl);
        return progressBarSteps[index];
    }
}
