<div class="wrapper">
    <input nxDatefield
           [(ngModel)]="dateHolder"
           [datepicker]="dateHolderDatePicker"
           [datefieldFilter]="dateFilter"
           [max]="max"
           [min]="min"
           name="dateHolder"
           type="hidden"
           [disabled]="disabled"
           #dateHolderInput="nxDatefield" />
    <nx-datepicker-toggle nxFormfieldSuffix [for]="dateHolderDatePicker"></nx-datepicker-toggle>
    <nx-datepicker [startAt]="startAt()" #dateHolderDatePicker></nx-datepicker>
</div>
