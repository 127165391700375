import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentType, StorageKey } from '@core/enum';
import { CustomerAttributes } from '@core/model/customer-data/customer-attributes';
import { Insured } from 'src/app/conditions-change/insured/insured';
import { HttpUtils } from '../../shared/http-util';
import { StateService } from './state.service';

const SERVER_SIDE_SERVICE_TICKET_URL_COOKIE = 'serviceTicketUrl';

@Injectable({providedIn: 'root'})
export class CustomerDataService {

    constructor(private readonly stateService: StateService,
                private readonly http: HttpClient) {
    }

    public validateLoggedUser(ticket: string): Promise<CustomerAttributes> {
        return this.http.get('/api/secure/me', {params: {token: ticket}, withCredentials: true}).toPromise()
            .then((resp: any) => (resp as CustomerAttributes))
            .then(data => {
                this.storeTicket(ticket);
                this.storeCustomerData(data);

                return data;
            })
            .catch(() => {
                this.storeTicket(null);
                this.storeCustomerData(null);
                HttpUtils.deleteCookie(SERVER_SIDE_SERVICE_TICKET_URL_COOKIE);
                return null;
            });
    }

    public isLogged(): boolean {
        return !!this.getCustomerData();
    }

    public checkIfTicketIsStillValid(): void {
        const ticket = this.getTicket();
        if (!ticket) {
            return;
        }

        this.validateLoggedUser(this.getTicket());
    }

    public getTicket(): string {
        return this.stateService.get(StorageKey.CAS_TICKET);
    }

    private storeTicket(ticket: string): void {
        this.stateService.set(StorageKey.CAS_TICKET, ticket);
    }

    private storeCustomerData(customerData: CustomerAttributes): void {
        this.stateService.set(StorageKey.CUSTOMER_DATA, customerData);
    }

    public getCustomerData(): CustomerAttributes {
        return this.stateService.get(StorageKey.CUSTOMER_DATA) as CustomerAttributes;
    }

    public customerToInsured(attrs: CustomerAttributes): Insured {
        const result = new Insured();
        result.firstName = attrs.firstName;
        result.surname = attrs.surname;
        result.pesel = attrs.pesel;
        result.documentNo = attrs.docNumber || attrs.passport || attrs.otherDocNumber;
        result.documentType = this.getDocumentTypeFromCustomer(attrs);

        return result;
    }

    private getDocumentTypeFromCustomer(attrs: CustomerAttributes): DocumentType {
        if (attrs.docNumber) {
            return DocumentType.ID;

        } else if (attrs.passport) {
            return DocumentType.PASSPORT;

        }

        return null;
    }

}
